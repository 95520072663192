import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { TealiumService } from 'src/app/services/tealium.service';

@Component({
  selector: 'app-fca-download-popup',
  templateUrl: './fca-download-popup.component.html',
  styleUrls: ['./fca-download-popup.component.less']
})
export class FcaDownloadPopupComponent implements OnInit {
  @Input() downloadUrlData: any;
  @Input() popupDisplayData: any;
  @Input() formDataDropDown: any;
  modalToggle = false;
  downloadMapUrls = [];
  downloadLicenseUrls = [];
  @Output() closePopup = new EventEmitter<boolean>();
  oemName!: string;

  constructor(
    private dataService: DataService,
    private tealiumService: TealiumService
  ) { }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
    for (const x of this.downloadUrlData) {
      if (x.NAME === 'index.download_map') {
        this.downloadMapUrls = x.URL_DESC.split(';');
      }
      if (x.NAME === 'index.download_license') {
        this.downloadLicenseUrls = x.URL_DESC.split(';');
      }
    }
    this.openModal();
  }

  openModal() {
    this.modalToggle = true;
  }

  cancel() {
    this.modalToggle = false;
    this.closePopup.emit(this.modalToggle);
    this.tealiumService.trackEvent({
      event_name: 'navshop_click',
      click_text: 'click_on_cancel_button',
      map_name: this.popupDisplayData.mapName,
      map_version: this.popupDisplayData.mapVersion,
      car_brand: this.formDataDropDown.car_brand,
      car_model: this.formDataDropDown.car_model,
      nav_system: this.formDataDropDown.nav_system
    });
  }

  ngOnDestroy(): void {
    this.downloadMapUrls = [];
    this.downloadLicenseUrls = [];
  }

  downloadAndTrackProgress(link: string, type: string) {
    this.tealiumService.trackEvent({
      event_name: 'navshop_click',
      click_text: `click_on_${type}_link`,
      download_link: link,
      map_name: this.popupDisplayData.mapName,
      map_version: this.popupDisplayData.mapVersion,
      car_brand: this.formDataDropDown.car_brand,
      car_model: this.formDataDropDown.car_model,
      nav_system: this.formDataDropDown.nav_system
    });
    this.modalToggle = false;
    this.closePopup.emit(this.modalToggle);
  }

}
