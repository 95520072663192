import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor() { }

  getTranslationUrl(oemName: string, localeCode: string) {
    return oemName ? `${oemName}/${localeCode}` : 'error found';
  }

  getDefaultLocaleValue() {
    return {
      localeCode: environment.defaultLangCode,
      localName: environment.defaultLangName
    }
  }
}
