<div class="moreInfoDiv">
    <div id="downloadMoreInfo" class="downloadMoreInfo" *ngIf="subscribedData1=='download'" style="display: block;">
        <div class="helpSpanClass">
            <!-- 					<h1>DOWNLOAD</h1> <br> -->
            <h3 style="color: #428bca !important;" [innerHtml]="'help.download1' | translate">
            </h3>
            <ul>
                <li  [innerHtml]="'help.download2' | translate"></li>
                <li [innerHtml]="'help.download29' | translate"></li>
            </ul>
            <ol value="1" style="margin-left: 75px;">
                <li [innerHtml]="'help.download30' | translate"></li>
                <li [innerHtml]="'help.download31' | translate"></li>
                <li [innerHtml]="'help.download51' | translate"></li>
            </ol>

            <ul>
                <li [innerHtml]="'help.download3' | translate"></li>
            </ul>
            <ul type="circle" style="margin-left: 75px;">
                <li [innerHtml]="'help.download24' | translate"></li>
            </ul>
            <ol value="1" style="margin-left: 150px;">
                <li [innerHtml]="'help.download4' | translate"></li>
                <li [innerHtml]="'help.download5' | translate"></li>
                <li [innerHtml]="'help.download52' | translate"></li>
            </ol>
            <ul type="circle" style="margin-left: 75px;">
                <li [innerHtml]="'help.download17' | translate"></li>
            </ul>
            <ol value="1" style="margin-left: 150px;">
                <li>
                    <span [innerHtml]="('help.download18' | translate) + ' '"></span>
                    <span style="color:red" [innerHtml]="'help.download27' | translate"> </span>
                </li>
                <li [innerHtml]="'help.download19' | translate"></li>
            </ol>
            <b style="margin-left: 190px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download32' | translate"></b><br>
            <img style="margin-left: 190px; height: 300px;" src="assets/images/ssangyong/helpSettings1.png"> <br><br>
            <div>
                <div style="margin-left: 190px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download33' | translate"></div>
            </div>
            <br>
            <img style="margin-left: 190px; height: 300px;width:500px;" src="assets/images/ssangyong/rexton1.png"><br/>
            <b style="margin-left: 190px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download53' | translate"></b><br>
            <img style="margin-left: 190px;" src="assets/images/ssangyong/arrena-musso.JPG">
            <br><br>


            <ol start="3" style="margin-left: 150px;">
                <li [innerHtml]="'help.download20' | translate"></li>

            </ol>
            <b style="margin-left: 190px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download32' | translate"></b><br>
            <img style="margin-left: 190px; height: 300px;" src="assets/images/ssangyong/helpSettings2.png"> <br><br>
            <div style="margin-left: 190px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download33' | translate"></div><br>
            <img style="margin-left: 190px; height: 300px;width:500px;" src="assets/images/ssangyong/rexton2.png">
            <br/>
            <b style="margin-left: 190px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download53' | translate"></b><br>
            <img style="margin-left: 190px;" src="assets/images/ssangyong/storage-setup.JPG">
            <br><br>
            <ol start="4" style="margin-left: 150px;">

                <li [innerHtml]="'help.download21' | translate"></li>
                <li>
                    <span style="color:red;" [innerHtml]="('help.download25' | translate)+' '"></span>
                    
                </li>
            </ol>
            <b style="margin-left: 190px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download58' | translate"></b><br>
            <img style="margin-left: 190px;height: 190px;" src="assets/images/ssangyong/system4.jpg"><br/>
            <div style="margin-left: 190px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download33' | translate"></div><br>
            <img style="margin-left: 190px; height: 300px;width:500px;" src="assets/images/ssangyong/rexton3.png">
            <br><br>

            <b style="margin-left: 190px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download41' | translate"></b><br>
            <img style="margin-left: 190px;" src="assets/images/ssangyong/version-info.JPG">
            <br><br>





            <br>
            <ul>
                <li [innerHtml]="'help.download6' | translate"></li>
                <li [innerHtml]="'help.download54' | translate"></li>
                <li [innerHtml]="'help.download55' | translate"></li>
                <li [innerHtml]="'help.download7' | translate"></li>
            </ul>
            <br>

            <h3 [innerHtml]="'help.download9' | translate">
            </h3>
            <ol value="1">
                <li>
                    <span [innerHtml]="'help.download10' | translate"></span>
                    <a href="http://ssangyong.navshop.com/downloads/"> http://ssangyong.navshop.com/downloads/</a>
                    <span [innerHtml]="'help.download26' | translate"></span>
                </li>
                <li [innerHtml]="'help.download11' | translate"></li>
                <li [innerHtml]="'help.download12' | translate"></li>
                <li [innerHtml]="'help.download13' | translate"></li>
                <li [innerHtml]="'help.download14' | translate"></li>
            </ol>
        </div>

    </div>
    <div id="transferMoreInfo" *ngIf="subscribedData1=='transfer'" class="transferMoreInfo">
        <div class="helpSpanClass">
            <h3 style="color: #428bca !important;" [innerHtml]="'help.transfer21' | translate">
            </h3>
            <h3 [innerHtml]="'help.transfer1' | translate">
            </h3>
            <br>
            <ul type="none">
                <li><i [innerHtml]="'help.transfer2' | translate"></i></li>
            </ul>
            <br> <span style="font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.transfer3' | translate"></span>
            <ol value="1">
                <li [innerHtml]="'help.transfer4' | translate"></li>
                <li [innerHtml]="'help.transfer5' | translate"></li>
            </ol>
            <br> <span style="font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.transfer6' | translate"></span>
            <ul class="bulletType">
                <li [innerHtml]="'help.transfer7' | translate"></li>
            </ul>
            <br>
            <h3 [innerHtml]="'help.transfer8' | translate">
            </h3>
            <h3 style="padding-left: 35px;" [innerHtml]="'help.transfer51' | translate"></h3>
            <ol value="1">
                <li [innerHtml]="'help.transfer9' | translate"></li>
                <li [innerHtml]="'help.transfer10' | translate"></li>
                <li> <span [innerHtml]="'help.transfer11' | translate"></span>
                    <p>
                        <span style="color:red" [innerHtml]="('help.transfer33' | translate) + ' '"></span>
                        <span [innerHtml]="'help.transfer34' | translate"></span>
                    </p>
                </li>
                <li [innerHtml]="'help.transfer52' | translate"></li>
                <h3 [innerHtml]="'help.transfer53' | translate"></h3>
                    
                <li>
                    <span [innerHtml]="'help.transfer12' | translate"></span>
                    <p>
                        <span style="color:red" [innerHtml]="('help.transfer33' |translate) + ' '"></span>
                        <span [innerHtml]="'help.transfer37' | translate"></span><br/>
                        <span style="color:white;margin-left: 80px" ></span>
                        <span [innerHtml]="'help.transfer54' | translate"></span>
                    </p>
                </li>
            </ol>
            <br/>
            
            <br>
            <ul type="none">
                <li><span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer13' | translate"></span>
                </li>
                <li [innerHtml]="'help.transfer14' | translate"></li>
                <br>
                <li><span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer15' | translate"></span>
                </li>
                <li [innerHtml]="'help.transfer16' | translate"></li>
                <li [innerHtml]="'help.transfer17' | translate"></li>
            </ul>
            <br>
            <ol start="6">
                <li [innerHtml]="'help.transfer18' | translate"></li>
            </ol>
            <br>
            <ul type="none">
                <li>
                    <span style="font-family:Montserrat-Bold;font-weight:bold;" [innerHtml]="('help.transfer19' |
                        translate ) + ' '"></span>
                    <span [innerHtml]=" 'help.transfer35' | translate"></span>
                </li><br>
                <li>
                    <span style="font-family:Montserrat-Bold;font-weight:bold;" [innerHtml]="('help.transfer20' |
                        translate)+' '"></span>
                    <span [innerHtml]="'help.transfer36' | translate"></span>
                </li>
            </ul>
            <br>

            <h3 style="padding-left: 20px;" [innerHtml]="'help.transfer55' | translate"></h3>
            <ol value="1">
                <li [innerHtml]="'help.transfer56' | translate"></li>
                
                    
                <li>
                    <span [innerHtml]="'help.transfer57' | translate"></span><br/><br/>
                    <p>
                        <span style="color:red" [innerHtml]="('help.transfer33' |translate) + ' '"></span>
                        <span [innerHtml]="'help.transfer58' | translate"></span><br/>
                    </p>
                </li>
                <li [innerHtml]="'help.transfer59' | translate"></li>
            </ol>
            <br/>
            <ul class="bulletType">
                <li><span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer13' | translate"></span>
                </li>
                <li class="margin-left" [innerHtml]="'help.transfer60' | translate"></li>
                <br>
                <li><span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer15' | translate"></span>
                </li>
                <li class="margin-left" [innerHtml]="'help.transfer61' | translate"></li>
                <li class="margin-left" [innerHtml]="'help.transfer62' | translate"></li>
            </ul>
            <br/>
            <ol start="4">
                <li [innerHtml]="'help.transfer63' | translate"></li>
                
            </ol>
            <br/>
            <ul class="bulletType">
                <li><span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer13' | translate"></span><span 
                        [innerHtml]="'help.transfer66' | translate"></span>
                </li>
                <li><span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer20' | translate"></span><span 
                        [innerHtml]="'help.transfer67' | translate"></span>
                </li>
                
            </ul>
            <br/>
            <h3 style="color: #428bca !important;" [innerHtml]="'help.transfer22' | translate">
            </h3>
            <h3 [innerHtml]="'help.transfer23' | translate">
            </h3>
            <br>
            <ul type="none">
                <li><i [innerHtml]="'help.transfer2' | translate"></i></li>
            </ul>
            <br> <span style="font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.transfer3' | translate"></span>
            <ol value="1">
                <li [innerHtml]="'help.transfer4' | translate"></li>
                <li [innerHtml]="'help.transfer5' | translate"></li>
            </ol>
            <br> <span style="font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.transfer6' | translate"></span>
            <ul class="bulletType">
                <li [innerHtml]="'help.transfer7' | translate"></li>
            </ul>
            <br>
            <h3 [innerHtml]="'help.transfer24' | translate">
            </h3>
            <ol value="1">
                <li [innerHtml]="'help.transfer25' | translate"></li>
                <li>
                    <span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer68' | translate">
                    </span><br>
                    <span [innerHtml]="'help.transfer26' | translate"></span><br><br>
                    <span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer69' | translate">
                    </span><br>
                    
                    <span [innerHtml]="'help.download38' | translate"></span><br><br>

                    <span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer70' | translate">
                    </span><br/>
                    <span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer71' | translate">
                    </span><br><br/>
                    <span style="font-family:Montserrat-Bold;font-weight:bold;" [innerHtml]="'help.transfer72' |
                        translate"></span><br>
                    <span [innerHtml]="'help.download36' | translate"></span><br><br>
                    <span style="font-family:Montserrat-Bold;font-weight:bold;" [innerHtml]="'help.transfer73' |
                        translate"></span><br>
                        <span [innerHtml]="'help.download40' | translate"></span><br><br>
                        <span style="margin-left:45px;" [innerHtml]="'help.transfer27' | translate"></span> <br>
                        <span style="margin-left:45px;" [innerHtml]="'help.transfer74' | translate"></span> <br><br/>
                    <span style="font-family:Montserrat-Bold;font-weight:bold;" [innerHtml]="'help.transfer75' |
                        translate"></span><br>
                    <span [innerHtml]="'help.transfer76' | translate"></span>
                </li>
            </ol>
            <span style="margin-left:45px;" [innerHtml]="'help.transfer27' | translate"></span> <br>
            <br>
            <ul type="none">
                <li><span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer13' | translate"></span>
                </li>
                <li [innerHtml]="'help.transfer28' | translate"></li>
                <br>
                <li><span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="'help.transfer15' | translate"></span>
                </li>
                <li [innerHtml]="'help.transfer29' | translate"></li>
            </ul>
            <br>
            <ol start="3">
                <li [innerHtml]="'help.transfer30' | translate"></li>
            </ol>
            <br>
            <ul type="none">
                <li>
                    <span style="font-family:Montserrat-Bold;font-weight:bold;" [innerHtml]="('help.transfer19' |
                    translate)+' '"></span>
                    <span [innerHtml]="'help.transfer31' | translate"></span>
                </li><br>
                <li>
                    <span style="font-family:Montserrat-Bold;font-weight:bold;"
                        [innerHtml]="('help.transfer20' |translate)+' '"></span>
                    <span [innerHtml]="'help.transfer32' | translate"></span>
                </li>

            </ul>
        </div>
    </div>
    <div id="installMoreInfo" *ngIf="subscribedData1=='install'" class="installMoreInfo">
        <div class="helpSpanClass">

            <h3 style="color: #428bca !important;" [innerHtml]="'help.install5' | translate">
            </h3>
            <h3 style="padding-left: 20px !important;" [innerHtml]="'help.install51' | translate">
            </h3>
            <br>
            <ol value="1">
                <li [innerHtml]="'help.install2' | translate"></li>
                <li [innerHtml]="'help.install3' | translate"></li>
                <li [innerHtml]="'help.install4' | translate"></li>
            </ol>

            <h3 [innerHtml]="'help.install52' | translate">
            </h3>
            <ol start="1" >
                <li [innerHtml]="'help.install53' | translate"></li>
                <li [innerHtml]="'help.install54' | translate"></li>
                <li [innerHtml]="'help.install55' | translate"></li>
            </ol>
            <img style="margin-left: 30px;height: 190px;" src="assets/images/ssangyong/arrena-musso.JPG">

            <ol start="4" >
                <li [innerHtml]="'help.install56' | translate"></li>
                
            </ol>
            <img style="margin-left: 30px;height: 190px;" src="assets/images/ssangyong/update.png">
            <ol start="5" >
                <li [innerHtml]="'help.install57' | translate"></li>
                
            </ol>
            <img style="margin-left: 30px;height: 190px;" src="assets/images/ssangyong/update-popup.png">
            <ol start="6" >
                <li [innerHtml]="'help.install58' | translate"></li>
                
            </ol>
            <img style="margin-left: 30px;height: 190px;" src="assets/images/ssangyong/update-progress.JPG">
            <ol start="7" >
                <li [innerHtml]="'help.install59' | translate"></li>
                
            </ol>
            
            <h3 style="color: #428bca !important;" [innerHtml]="'help.install6' | translate">
            </h3>
            <br>
            <ol value="1">
                <li [innerHtml]="'help.install7' | translate"></li>
                <li [innerHtml]="'help.install8' | translate"></li>
                <li [innerHtml]="'help.install9' | translate"></li>
            </ol>

            <b style="margin-left: 75px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download32' | translate"></b><br>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/helpSettings2.png"> <br><br>
            <div style="margin-left: 75px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download33' | translate"></div><br>
            <img style="margin-left: 75px; height: 300px;width:500px;" src="assets/images/ssangyong/rexton4.png">
            <br><br>

            <ol start="4">
                <li [innerHtml]="'help.install10' | translate"></li>
            </ol>
            <div style="margin-left: 75px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download32' | translate">
        </div><br>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/system3.png"> <br><br>
            <div style="margin-left: 75px;font-family:Montserrat-Bold;font-weight:bold;"
                [innerHtml]="'help.download33' | translate"></div><br>
            <img style="margin-left: 75px; height: 300px;width:500px;" src="assets/images/ssangyong/rexton5.png">
            <br><br>
            <ol start="5">
                <li>
                    <span [innerHtml]="('help.install11' | translate) + ' '"></span>
                    <span [innerHtml]="'help.install13' | translate"></span>
                </li>
            </ol>
            <img style="margin-left: 75px;height:300px;" src="assets/images/ssangyong/reboot.png"> <br><br>
            <ol start="6">
                <li [innerHtml]="'help.install12' | translate">
                </li>
            </ol>

            <h3 style="color: #428bca !important;" [innerHtml]="'help.install60' | translate"></h3>
            <ol start="1" >
                <li [innerHtml]="'help.install61' | translate"></li>
                <li [innerHtml]="'help.install62' | translate"></li>
                <li [innerHtml]="'help.install63' | translate"></li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/arrena-musso.JPG">
            <ol start="4">
                <li [innerHtml]="'help.install64' | translate">
                </li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/update.png">
            <ol start="5">
                <li [innerHtml]="'help.install65' | translate">
                </li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/upgrade-install.png">
            <ol start="6">
                <li [innerHtml]="'help.install66' | translate">
                </li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/upgrade-progress-percentage.png">
            <ol start="7">
                <li [innerHtml]="'help.install67' | translate">
                </li>
            </ol>

            <h3 style="color: #428bca !important;" [innerHtml]="'help.install68' | translate"></h3>
            <ol start="1" >
                <li [innerHtml]="'help.install69' | translate"></li>
                <li [innerHtml]="'help.install70' | translate"></li>
                <li [innerHtml]="'help.install71' | translate"></li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/arrena-musso.JPG">
            <ol start="4">
                <li [innerHtml]="'help.install72' | translate">
                </li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/update.png">
            <ol start="5">
                <li [innerHtml]="'help.install73' | translate">
                </li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/firmware-upgrade.png">
            <ol start="6">
                <li [innerHtml]="'help.install74' | translate">
                </li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/upgrade-warning.png">
            <ol start="7">
                <li [innerHtml]="'help.install75' | translate">
                </li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/firmware-upgrade-progress.png">
            <ol start="8">
                <li [innerHtml]="'help.install76' | translate">
                </li>
            </ol>
            <br/>

            <h3 style="color: #428bca !important;" [innerHtml]="'help.install77' | translate"></h3>
            <ol start="1" >
                <li [innerHtml]="'help.install78' | translate"></li>
                <li [innerHtml]="'help.install79' | translate"></li>
                <li [innerHtml]="'help.install80' | translate"></li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/arrena-musso.JPG">
            <ol start="4">
                <li [innerHtml]="'help.install81' | translate">
                </li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/update.png">
            <ol start="5">
                <li [innerHtml]="'help.install82' | translate">
                </li>
            </ol>
            <img style="margin-left: 75px;height:300px; " src="assets/images/ssangyong/update-popup-dab.png">
            <ol start="6">
                <li [innerHtml]="'help.install83' | translate">
                </li>
            </ol>
        </div>
    </div>
</div>