import { Component, OnInit, Input } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { TealiumService } from 'src/app/services/tealium.service';

@Component({
  selector: 'app-fca-my-downloads',
  templateUrl: './fca-my-downloads.component.html',
  styleUrls: ['./fca-my-downloads.component.less']
})
export class FcaMyDownloadsComponent implements OnInit {

  downloadUrlData: {
    ID: number,
    PACKAGE_ID: number,
    NAME: string,
    URL_DESC: string
  }[] = [];
  oemName!: string;
  @Input() package: any;
  @Input() formDataDropDown: any;
  downloadPopupToggle = false;
  popupDisplayData = {};
  selectedLocale!: boolean;

  constructor(
    private dataService: DataService,
    private translate: TranslateService,
    private tealiumService: TealiumService
  ) {
    this.selectedLocale = this.translate.currentLang.includes('de_DE') ? true : false;
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.package = [];
      this.selectedLocale = this.translate.currentLang.includes('de_DE') ? true : false;
    });

  }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
  }

  getUrlData(id: number, name: string, version: string, size?: string) {
    this.downloadUrlData = [];
    this.downloadUrlData = this.dataService.getUrlMapData(id);
    this.popupDisplayData = {
      mapName: name,
      mapVersion: version
    }
    this.downloadPopupToggle = true;
    this.tealiumService.trackEvent({
      event_name: 'navshop_click',
      click_text: 'click_on_download_button',
      map_name: name,
      map_version: version,
      map_size: size,
      car_brand: this.formDataDropDown.car_brand,
      car_model: this.formDataDropDown.car_model,
      nav_system: this.formDataDropDown.nav_system
    });
  }

  closePopup(e: any) {
    this.downloadPopupToggle = e;
  }

}
