<div [ngClass]='rtlLocale'>
    <div *ngIf="downloadUrlData.length>0" id="downloadPopup1" class="modal" [ngClass]="{'modal-display':modalToggle}">
        <div class="container downloadPopup">
            <div class="innerTitleDiv dialogDownloadTitle">
                <span class="innerTitleLeftSpan" [innerHtml]="popupDisplayData.mapName | translate"></span>
                <span class="innerTitleRightSpan" [innerHtml]="popupDisplayData.mapVersion | translate"></span>
            </div>
            <div class="mainPopdiv" id="downloadApplet">
                <div id="altDiv" [ngClass]="{'addScroll': downloadMapUrls.length>1}">
                    <p class="download-below-files-style" *ngIf="popupDisplayData.mapName === 'South Korea'"
                        [innerHTML]="'index.korea_text' | translate"></p>
                    <p class="download-below-files-style">{{'index.download_below_files' | translate}}</p>

                    <div *ngFor="let value of downloadMapUrls;let i=index">
                        <a class="link-style" href="{{value}}" target="_blank"
                            [innerHtml]="popupDisplayData.mapName | translate"></a>
                    </div>
                    <br>
                </div>
            </div>
            <div class="innerDownloadButtonDiv">
                <input (click)="cancel()" type="button" class="innerDownloadButton" id="cancelButton"
                    value="{{'index.cancel' | translate}}">
            </div>
        </div>
    </div>
</div>