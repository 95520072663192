import { Component, Input } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-iveco-help-section',
  templateUrl: './iveco-help-section.component.html',
  styleUrls: ['./iveco-help-section.component.less']
})
export class IvecoHelpSectionComponent {

  @Input() subscribedData1: any;
  rtlLocale = '';

  constructor(private translate: TranslateService) {
    if (this.translate.currentLang === 'iveco/ar' || this.translate.currentLang === 'iveco/he_IL') {
      this.rtlLocale = 'rtl';
    } else {
      this.rtlLocale = 'non-rtl';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.translate.currentLang === 'iveco/ar' || this.translate.currentLang === 'iveco/he_IL') {
        this.rtlLocale = 'rtl';
      } else {
        this.rtlLocale = 'non-rtl';
      }
    });
  }

}
