import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-ssangyong-drop-down',
  templateUrl: './ssangyong-drop-down.component.html',
  styleUrls: ['./ssangyong-drop-down.component.less']
})
export class SsangyongDropDownComponent implements OnInit, AfterViewInit {

  @Input() vehicleInfo: {
    ID: number,
    NAME: string,
    OEM_ID: number
  }[] = [];
  dropDownForm!: FormGroup;
  firstDorpDownValue: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    PARENT_ID: number,
    VEHICLE_MODEL_TYPE_ID: number
  }[] = [];
  secondDropDownValue: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    PARENT_ID: number,
    VEHICLE_MODEL_TYPE_ID: number
  }[] = [];
  package: [] = [];
  packageInfo: {
    ID: number,
    NAME: string,
    DESCRIPTION: string,
    SIZE: string,
    VERSION: string,
    IMAGE_NAME: string,
    UPLOAD_DATE: string
  }[] = [];
  submitted = false;

  brandDropDownToggle: boolean = false;
  modelDropDownToggle: boolean = false;
  naviDropDownToggle: boolean = false;

  russiaLocaleSelected = false;
  selectedSeriesName = "";

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.russiaLocaleSelected = this.translate.currentLang.includes('ru_RU') ? true : false;
    this.dropDownForm = this.fb.group({
      firstDropDown: [null, [Validators.required]],
      firstDropDownVal: [null, [Validators.required]],
      secondDropDown: [null, [Validators.required]],
      secondDropDownVal: [null, [Validators.required]],
    });
    for (const val of this.vehicleInfo) {
      this.firstDorpDownValue = this.dataService.getFirstDropDownValue(val.ID);
      if (this.firstDorpDownValue.length > 0) {
        break;
      }
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.dropDownForm.controls;
  }

  ngAfterViewInit() {
    this.changeValueIntoInnerHtml('index.firstDropDown', 1);
    this.changeValueIntoInnerHtml('index.secondDropDown', 2);
  }

  changeValueIntoInnerHtml(key: string, numberInput: number) {
    const input: any = document.querySelectorAll("input")[numberInput];
    if (input) {
      this.translate.get(key).subscribe((result: any) => {
        input.innerHTML = result;
        if (this.dropDownForm.value.firstDropDown) {
          this.dropDownForm.patchValue({
            firstDropDown: this.dropDownForm.value.firstDropDown,
            firstDropDownVal: this.dropDownForm.value.firstDropDownVal,
            secondDropDown: null,
            secondDropDownVal: null,
          });
        } else {
          this.dropDownForm.patchValue({
            firstDropDown: null,
            firstDropDownVal: null,
            secondDropDown: null,
            secondDropDownVal: null,
          });
        }

        setTimeout(() => {
          input.value = input.textContent;
        }, 10);
      });
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.russiaLocaleSelected = this.translate.currentLang.includes('ru_RU') ? true : false;
        input.innerHTML = this.translate.instant(key);
        this.dropDownForm.patchValue({
          firstDropDown: null,
          firstDropDownVal: null,
          secondDropDown: null,
          secondDropDownVal: null,
        });
        setTimeout(() => {
          input.value = input.textContent;
        }, 10);
      });
    }
  }

  openBrandDropDown() {
    this.brandDropDownToggle = !this.brandDropDownToggle;
    this.dropDownForm.controls['firstDropDown'].valueChanges.subscribe(value => {
      if (!value) {
        this.dropDownForm.reset();
        this.changeValueIntoInnerHtml('index.firstDropDown', 1);
        this.changeValueIntoInnerHtml('index.secondDropDown', 2);
      }
    });
  }

  openModelDropDown() {
    this.modelDropDownToggle = !this.modelDropDownToggle;
    this.dropDownForm.controls['firstDropDown'].valueChanges.subscribe(value => {
      if (value !== this.dropDownForm.value['firstDropDown']) {
        this.secondDropDownValue = [];
        this.dropDownForm.patchValue({
          secondDropDown: null,
          secondDropDownVal: null,
        });
        this.changeValueIntoInnerHtml('index.secondDropDown', 2);
      }
    });
  }

  closeDropDown() {
    this.brandDropDownToggle = false;
    this.modelDropDownToggle = false;
    this.naviDropDownToggle = false;
  }

  fetchCarModelData(id: number | null, name: string | null) {
    this.dropDownForm.patchValue({
      firstDropDown: id,
      firstDropDownVal: name
    });
    if (this.dropDownForm.value.firstDropDown) {
      this.secondDropDownValue = this.dataService.getOtherDropDownValues(Number(this.dropDownForm.value.firstDropDown));
    }
    this.brandDropDownToggle = false;
  }

  fetchCarNaviData(id: number | null, name: string | null) {
    this.dropDownForm.patchValue({
      secondDropDown: id,
      secondDropDownVal: name
    });
    this.modelDropDownToggle = false;
  }
  
  fetchPackageData() {
    this.submitted = true;
    this.package = [];
    if (this.dropDownForm.invalid) {
      return;
    }
    this.selectedSeriesName = this.dropDownForm.value.secondDropDownVal;
    const packageId: {
      PACKAGE_ID: number,
      VEHICLE_MODEL_ID: number
    }[] = this.dataService.getPackageId(this.getPackageDataDropDownValue());
    let result: any = [];
    for (const val of packageId) {
      result.push(this.dataService.getPackageData(val.PACKAGE_ID));
    }
    this.package = result;
    this.submitted = false;

  }

  getPackageDataDropDownValue() {
    const oemName = this.dataService.getOemName();
    if (oemName === 'stellantis') {
      return this.dropDownForm.value.thirdDropDown
    } else if (oemName === 'iveco') {
      return this.dropDownForm.value.firstDropDown
    } else {
      return this.dropDownForm.value.secondDropDown
    }
  }
}
