<div [ngClass]='rtlLocale'>
    <div class="tt-my-downloads">
        <div *ngIf="package.length>0" class="container myDownloads" id="myDownloads">
            <h1 class="container downloadTitle">
                {{'index.download' | translate}}
            </h1>
            <hr class="container dividerColor {{oemName}}-dividecolor-hrline">
            <div class="innerDownloadDiv-style" id="myInnerDownloadDiv">
                <div class="createDownloads" *ngFor="let data of package">
                    <div class="outerMainDiv" *ngFor="let val of data">
                        <div class="innerTitleDiv innercss">
                            <span class="innerTitleLeftSpan" [innerHtml]="val.NAME | translate"></span><br>
                            <span class="innerTitleRightSpan">{{val.VERSION}}
                            </span><br>
                        </div>
                        <hr class="hrLine innerhrline">
                        <div class="innerSupportDiv"><img class="innerMainDiv"
                                src="assets/images/{{oemName}}/{{val.IMAGE_NAME}}" alt="Download img">
                            <div class="innerSpanDiv innerSpanCss" innerHTML="{{'index.MapMessage' | translate}}"
                                [ngClass]="{'extend-margin-bottom-innerSpanCss': extendMarginToggle}">
                            </div>
                            <div class="innerButtonDiv"><b>{{val.SIZE}}</b><button
                                    class="innerDownloadButton commomButtonPrimary"
                                    (click)="getUrlData(val.ID,val.NAME,val.VERSION)">{{'index.download2' |
                                    translate | titlecase}}</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-mgn-download-popup *ngIf="downloadPopupToggle" (closePopup)="closePopup($event)"
    [downloadUrlData]="downloadUrlData" [popupDisplayData]="popupDisplayData"></app-mgn-download-popup>