<div class="moreInfoDiv">
    <div *ngIf="subscribedData1=='download'" id="downloadMoreInfo" class="downloadMoreInfo">
        <div class="helpSpanClass">
            <h3 class="text-style" id="helpDownload1" [innerHtml]="'help.download' | translate">
            </h3>
            <ul class="font-styling">
                <li [innerHtml]="'help.download1' | translate"></li>
                <li [innerHtml]="'help.download2' | translate"></li>
                <div class="install3-note" [innerHtml]="'help.download103' | translate"></div>
                <li>
                    <div [innerHtml]="'help.download3' | translate"></div>
                    <div class="install3-note" [innerHtml]="'help.download3Note' | translate">
                    </div>
                </li>
            </ul>
        </div>
    </div>

    <div *ngIf="subscribedData1=='transfer'" id="transferMoreInfo" class="transferMoreInfo">
        <div class="helpSpanClass">
            <h3 id="helpTransfer21" class="text-style" [innerHtml]="'help.transfer' | translate">
            </h3>
            <ul class="font-styling">
                <li [innerHtml]="'help.transfer1' | translate"></li>
                <li [innerHtml]="'help.transfer2' | translate"></li>
                <div [innerHtml]="'help.transferNote' | translate">
                </div>
                <div class="help-information-macro">
                    <div>
                        <span class="transfer-info-icon"></span>
                        <p class="transferFormat1" [innerHtml]="'help.transferFormat1' | translate">

                        </p>
                        <p class="transfer-win" [innerHtml]="'help.transferWin' |
                            translate"></p>
                        <ul class="transfer-win-step">
                            <li [innerHtml]="'help.transferWinFormat2' | translate"></li>
                            <li [innerHtml]="'help.transferWinFormat3' | translate"></li>
                            <li [innerHtml]="'help.transferWinFormat4' | translate"></li>
                            <li [innerHtml]="'help.transferWinFormat5' | translate"></li>
                            <li [innerHtml]="'help.transferWinFormat6' | translate"></li>
                        </ul>
                        <span class="transfer-win" [innerHtml]="'help.transferMac' |
                            translate"></span>
                        <ul class="transfer-win-step">
                            <li [innerHtml]="'help.transferMacFormat2' | translate"></li>
                            <li [innerHtml]="'help.transferMacFormat3' | translate"></li>
                            <li [innerHtml]="'help.transferMacFormat4' | translate"></li>
                            <li [innerHtml]="'help.transferMacFormat5' | translate"></li>
                            <li [innerHtml]="'help.transferMacFormat6' | translate"></li>
                        </ul>
                    </div>
                </div>
                <li [innerHtml]="'help.transfer3' | translate"></li>
            </ul>
        </div>
    </div>

    <div *ngIf="subscribedData1=='install'" id="installMoreInfo" class="installMoreInfo">
        <div class="helpSpanClass">
            <h3 id="helpInstall5" class="text-style" [innerHtml]="'help.install' | translate">
            </h3>
            <ul class="font-styling">
                <li [innerHtml]="'help.install1' | translate"></li>
                <li [innerHtml]="'help.install2' | translate"></li>
                <li>
                    <div [innerHtml]="'help.install3' | translate">
                    </div>
                    <div class="install3-note" [innerHtml]="'help.install3Note' | translate">
                    </div>
                </li>
                <li [innerHtml]="'help.install4' | translate"></li>
                <li [innerHtml]="'help.install5' | translate"></li>
            </ul>
        </div>
    </div>
    <div class="height-style"></div>
</div>