<div [ngClass]='rtlLocale'>
    <div class="moreInfoDiv">
        <div id="downloadMoreInfo" class="downloadMoreInfo" *ngIf="subscribedData1 === 'download'">
            <div class="helpSpanClass">
                <!-- 					<h1>DOWNLOAD</h1> <br> -->
                <h3 id="helpDownload1" style="color: #428bca !important;">
                    {{'help.download1' | translate}}
                </h3>

                <h3 id="helpDownload1" style="color: #428bca !important;">
                    {{'help.download37' | translate}}
                </h3>
                <ul>
                    <li>{{'help.download2' | translate}}</li>
                    <li>{{'help.download3' | translate}}</li>
                </ul>
                <ul type="circle" class="helpDownload5">
                    <li>{{'help.download4' | translate}}</li>
                    <li>{{'help.download5' | translate}}</li>
                </ul>
                <ul>
                    <li>{{'help.download17' | translate}}</li>
                </ul>
                <ul type="circle" class="helpDownload5">
                    <li>{{'help.download18' | translate}}</li>
                </ul>
                
                <ul class="helpDownload29">
                    ➢ {{'help.download29' | translate}}
                </ul>
                <ol value="1" class="helpDownload19">
                    <li>{{'help.download19' | translate}}
                    </li><li>{{'help.download20' | translate}}</li>

                </ol>
                <img class="deviceImage" src="assets/images/mitsubishi/device.png"> <br><br>

                <ul class="helpDownload29">
                    ➢ {{'help.download30' | translate}}
                </ul>

                <ol value="1" class="helpDownload19">
                    <li>{{'help.download31' | translate}}
                    </li><li>{{'help.download32' | translate}}<br>
                        <img style="height: 200px;" src="assets/images/mitsubishi/sdcardXplor.png"> <br><br>
                    </li><li>{{'help.download33' | translate}}
                    </li><li>{{'help.download34' | translate}}<br>
                        <img style="height: 45px;" src="assets/images/mitsubishi/version.png"> <br>
                </li></ol>

                <ul>
                    <li>{{'help.download6' | translate}}</li>
                    <li>{{'help.download7' | translate}}</li>
                    <li>{{'help.download8' | translate}}</li>
                </ul>
                <br>

                <h3 id="helpDownload1" style="color: #428bca !important;">
                    {{'help.download38' | translate}}
                </h3>

                <span>{{'help.download39' | translate}}</span>
                <div style="padding-left: 30px;">
                <ol value="1">
                    <li>{{'help.download40' | translate}}</li>
                    <li>{{'help.download41' | translate}}</li>
                    <img style="height: 240px;" src="assets/images/mitsubishi/Download_1.png"> <br>


                    <li>{{'help.download42' | translate}}</li>
                    <img style="height: 240px;" src="assets/images/mitsubishi/download_2.png"> <br>
                    <li>{{'help.download43' | translate}}</li>

                </ol>
                <div style="padding-left: 30px;">
                <p>{{'help.download44' | translate}}</p>

                

                <p class="red">{{'help.download46' | translate}}</p>
                <p class="red">{{'help.download47' | translate}}</p>

                <img style="height: 240px;" src="assets/images/mitsubishi/download_3.png"> <br>

                <br></div></div>

                <h3 id="helpDownload35">
                    {{'help.download35' | translate}}
                </h3>
                <ol value="1">
                    <li [innerHTML]="'help.download36' | translate"></li>
                    <li>{{'help.download11' | translate}}</li>
                    <li>{{'help.download12' | translate}}</li>
                </ol>
                <div class="helpDownload13">
                    3.1.&nbsp;&nbsp;{{'help.download13' | translate}}<br>
                    3.2.&nbsp;&nbsp;{{'help.download14' | translate}}
                </div>
                <ol>
                    <li value="4">{{'help.download15' | translate}}</li>
                </ol>
            </div>

        </div>
        <div id="transferMoreInfo" class="transferMoreInfo" *ngIf="subscribedData1 === 'transfer'">
            <div class="helpSpanClass">
                <h3 id="helpTransfer21" style="color: #428bca !important;">
                    &lt;{{'help.transfer21' | translate}}&gt;
                </h3>

                <h3 id="helpTransfer1">
                    {{'help.transfer1' | translate}}
                </h3>
                <br>
                <ul type="none">
                    <li><i>{{'help.transfer2' | translate}}</i></li>
                </ul>
                <br> <span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer3' | translate}}</span>
                <ol value="1">
                    <li>{{'help.transfer4' | translate}}</li>
                    <li>{{'help.transfer5' | translate}}</li>
                </ol>
                <br> <span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer6' | translate}}</span>
                <ul class="bulletType">
                    <li>{{'help.transfer7' | translate}}</li>
                </ul>
                <br>

                <h3 id="helpTransfer8">
                    {{'help.transfer8' | translate}}
                </h3>
                <ol value="1">
                    <li>{{'help.transfer9' | translate}}</li>
                    <li>{{'help.transfer10' | translate}}</li>
                    <li>{{'help.transfer11' | translate}}<p><span class="red">{{'help.transfer33' | translate}}&nbsp;</span>{{'help.transfer34' | translate}}</p></li>
                    <li>{{'help.transfer12' | translate}}</li>
                </ol>
                <br>
                <ul type="none">
                    <li><span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer13' | translate}}</span></li>

                    <li>{{'help.transfer93' | translate}}</li>
                    <br>
                    <li><span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer15' | translate}}</span></li>

                    <li>{{'help.transfer94' | translate}}</li>
                    <li>{{'help.transfer17' | translate}}</li>
                </ul>
                <br>
                <ol start="5">
                    <li>{{'help.transfer18' | translate}}</li>
                </ol>
                <br>
                <ul type="none">
                    <li><span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer19' | translate}}</span>&nbsp;{{'help.transfer35' | translate}}</li>
                    <br>
                    <li><span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer20' | translate}}</span>&nbsp;{{'help.transfer36' | translate}}</li>

                </ul>

                <h3 style="color: #428bca !important;">
                    &lt;{{'help.transfer22' | translate}}&gt;
                </h3>
                <h3 id="helpTransfer1">
                    {{'help.transfer1' | translate}}
                </h3>
                <br>
                <div style="padding:0px 20px;">
                <ul type="none">
                    <li><i>{{'help.transfer2' | translate}}</i></li>
                </ul>
                <br> <span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer3' | translate}}</span>
                <ol value="1">
                    <li>{{'help.transfer4' | translate}}</li>
                    <li>{{'help.transfer5' | translate}}</li>
                </ol>
                <br> <span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer6' | translate}}</span>
                <ul class="bulletType">
                    <li>{{'help.transfer7' | translate}}</li>
                </ul>
                <br>


                <h3 id="helpTransfer8">
                    {{'help.transfer30' | translate}}
                </h3>

                    <p class="red" style="font-weight:bold;">{{'help.transfer31' | translate}}</p>
                <ol value="1">
                    <li>{{'help.transfer91' | translate}}</li>
                    
                    <li>{{'help.transfer38' | translate}}

                        
                        
                        <br>

                        <img src="assets/images/mitsubishi/transfer_2.png"><br>


                            <span class="red">{{'help.transfer41' | translate}}</span>
                    </li>

                    <li>{{'help.transfer92' | translate}}</li>
                </ol>

                <br>
                <ul type="none">
                    <li><span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer13' | translate}}</span></li>

                    <li>{{'help.transfer14' | translate}}</li>
                    <br>
                    <li><span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer15' | translate}}</span></li>

                    <li>{{'help.transfer16' | translate}}</li>
                    <li style="padding-top:20px;" class="red">{{'help.transfer17' | translate}}</li>
                </ul>
                <br>
                <ol start="4">
                    <li>{{'help.transfer95' | translate}}</li>
                </ol>
                <br>
                <ul type="none">
                    <li><span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer19' | translate}}</span>&nbsp;{{'help.transfer96' | translate}}</li>
                    <br>
                    <li><span style="font-weight: bold !important;" class="list-title-item">{{'help.transfer20' | translate}}</span>&nbsp;{{'help.transfer97' | translate}}</li>

                </ul></div>


            </div>
        </div>
        <div id="installMoreInfo" class="installMoreInfo" *ngIf="subscribedData1 === 'install'">
            <div class="helpSpanClass">
                <h3 id="helpInstall5" style="color: #428bca !important;">
                    &lt;{{'help.install5' | translate}}&gt;
                </h3>
                <br>
                <ol value="1">
                    <li>{{'help.install2' | translate}}</li>
                    <li>{{'help.install3' | translate}}</li>
                    <li>{{'help.install4' | translate}}</li>
                </ol>

                <h3 id="helpInstall5" style="color: #428bca !important;">
                    &lt;{{'help.install12' | translate}}&gt;
                </h3>
                <br>
                    <span class="red">{{'help.install13' | translate}}</span>
                <ol value="1">
                    <li>{{'help.install14' | translate}} </li>
                    <li>{{'help.install15' | translate}}</li>
                    <li>{{'help.install16' | translate}}<br>
                        <img src="assets/images/mitsubishi/install_1.png"></li>
                    <li>{{'help.install17' | translate}}<br>
                        <img src="assets/images/mitsubishi/install_2.png"></li>
                    <li>{{'help.install18' | translate}}<br>
                        <img src="assets/images/mitsubishi/install_3.png"></li>
                    <li>{{'help.install19' | translate}}</li>
                    <li>{{'help.install20' | translate}}<br>
                        <img width="390px" src="assets/images/mitsubishi/install_4.png"><br>
                        <img width="390px" src="assets/images/mitsubishi/install_5.png"></li>
                    <li>{{'help.install21' | translate}}
                        <ul type="none">
                            <li><i>{{'help.install22' | translate}}</i></li>
                        </ul>
                        <br>
                        <img src="assets/images/mitsubishi/install_6.png"> <br>
                        <img width="390px" src="assets/images/mitsubishi/install_7.png"></li>
                    <li>{{'help.install23' | translate}}<br>
                        <span class="red">{{'help.install25' | translate}}</span><br>
                        <img width="390px" src="assets/images/mitsubishi/install_8.png"></li>
                    <li class="last-l" [innerHTML]="'help.install24' | translate"></li>
                </ol>

            </div>
        </div>

    </div>
</div>
