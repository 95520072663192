import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { TranslationService } from 'src/app/services/translation.service';
import { filter } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { OEMCODE } from '../../constants/allOemCode';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  id = '';
  oemName!: string;
  localeDropDownToggle: boolean = false;
  localeDropDownHideShow: boolean = false;
  locales: {
    name: string,
    localeCode: string
  }[] = [];
  localeForm!: FormGroup;
  localeCode!: string;
  currentRoute = '';
  subscribedData = 'download'
  brand = '';
  oemCode = OEMCODE;
  isLocaleExist = false;
  localeName!: string;
  rtlLocale = '';
  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private translationService: TranslationService,
    private translateService: TranslateService,
    private router: Router,
    private titleService: Title,
    private cookieService: CookieService,
    private activatedRoute: ActivatedRoute
  ) {
    if (this.translateService.currentLang === 'melco/ar'
    || this.translateService.currentLang === 'mitsubishi/ar'
    || this.translateService.currentLang === 'iveco/ar' || this.translateService.currentLang === 'iveco/he_IL') {
      this.rtlLocale = 'rtl';
    } else {
      this.rtlLocale = 'non-rtl';
    }
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.translateService.currentLang === 'melco/ar'
        || this.translateService.currentLang === 'mitsubishi/ar'
        || this.translateService.currentLang === 'iveco/ar' || this.translateService.currentLang === 'iveco/he_IL') {
        this.rtlLocale = 'rtl';
      } else {
        this.rtlLocale = 'non-rtl';
      }
    });
  }

  ngOnInit(): void {
    const defaultLocale = this.translationService.getDefaultLocaleValue();
    this.oemName = this.dataService.getOemName();
    this.localeForm = this.fb.group({
      localeCode: ['', [Validators.required]],
      localeName: ['', [Validators.required]]
    });
    this.dataService.localeCodeCookie.next(defaultLocale.localeCode);
    const locCode = this.cookieService.get(`${environment.cookieName}`);
    this.dataService.getLocales(this.dataService.getOemName()).subscribe((res: any) => {
      this.locales = res;
      if (locCode !== '' && locCode !== undefined) {
        this.isLocaleExist = true;
        if (this.locales && this.locales.length > 0) {
          const selectedLocale = this.locales.filter((data: any) => {
            return data.localeCode === locCode;
          });
          this.localeName = selectedLocale[0].name;
          this.localeForm = this.fb.group({
            localeCode: [locCode, [Validators.required]],
            localeName: [this.localeName, [Validators.required]]
          });
        }
        this.localeSelected(this.isLocaleExist ? locCode : defaultLocale.localeCode);
      } else {
        this.isLocaleExist = false;
        this.localeForm = this.fb.group({
          localeCode: [defaultLocale.localeCode, [Validators.required]],
          localeName: [defaultLocale.localName, [Validators.required]]
        });

        this.localeSelected(defaultLocale.localeCode);
      }
    });
    this.localeDropDownHideShow = !this.router.url.includes('copyright');

    this.activatedRoute.queryParams.subscribe(data => {
      this.subscribedData = data.id ? data.id : this.subscribedData;
      this.brand = data.brand ? data.brand : this.brand;
    });

    this.dataService.isErrorPageLoaded.subscribe(res => {
      this.localeDropDownHideShow = res;
    });

    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url;
        if (this.currentRoute && this.currentRoute !== '') {
          this.checkRouteForTitle();
        }
      });
  }

  localeSelected(code?: any) {
    this.translateService.use(this.translationService.getTranslationUrl
      (this.dataService.getOemName(), code));
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.currentRoute = window.location.hostname;
      this.checkRouteForTitle();
    }, 1000);
  }

  updateLocale(localeCode: string, localeName: string) {
    this.cookieService.set(`${environment.cookieName}`, localeCode, new Date(new Date().getTime() + 1000 * 60 * 60), '', '', true, 'None');
    var locCode = this.cookieService.get(`${environment.cookieName}`);
    if (locCode.split('_').length >= 2) {
      locCode = `${locCode.split('_')[0]}_${locCode.split('_')[1].toUpperCase()}`;
    }
    this.dataService.localeCodeCookie.next(locCode);
    if (locCode !== '' && locCode !== undefined) {
      this.localeForm.patchValue({
        localeCode: locCode,
        localeName: localeName
      });
      this.localeSelected(localeCode);
      this.localeDropDownToggle = false;
    }

    this.checkRouteForTitle();
  }

  openDropDown() {
    this.localeDropDownToggle = !this.localeDropDownToggle;
  }

  closeDropDown() {
    this.localeDropDownToggle = false;
  }

  checkRouteForTitle() {
    if (window.location.hash.includes('help')) {
      this.translateService.get('help.title').subscribe((result: string) => {
        this.titleService.setTitle(result);
        this.localeDropDownHideShow = true;
        this.changeValueIntoInnerHtml(result);
      });
    } else if (window.location.hash.includes('copyright')) {
      this.titleService.setTitle(`${this.oemName.replace(this.oemName[0], this.oemName[0].toUpperCase())} | Copyright`)
      this.localeDropDownHideShow = false;
    } else if (window.location.href.includes('downloads/apps')) {
      this.titleService.setTitle('Apps');
    } else {
      this.translateService.get('index.title').subscribe((result: string) => {
        this.localeDropDownHideShow = true;
        this.subscribedData = 'download';
        this.titleService.setTitle(result);
        this.changeValueIntoInnerHtml(result);
      });
    }
  }

  getBannerNameBasedOnOem(oem: string) {
    switch (oem) {
      case 'melco':
        return 'header.jpg';
      case 'mitsubishi':
        return 'MGNHeaderImage.jpg';
      default:
        return 'Logo.png';
    }
  }

  changeValueIntoInnerHtml(key: string) {
    const input: any = document.getElementById("title");
    if (input) {
      input.textContent = key;
      input.value = input.textContent;
    }
  }
}
