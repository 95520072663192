<div [ngClass]='rtlLocale'>
    <div class="moreInfoDiv">
        <div id="downloadMoreInfo" class="downloadMoreInfo" style="display: block;" *ngIf="subscribedData1=='download'">
            <div class="helpSpanClass">
                <h3 id="helpDownload1" style="color: #428bca !important;" [innerHtml]="'help.download1' |
            translate">
                </h3>
                <h3 id="helpDownload1" style="color: #428bca !important;" [innerHtml]="'help.download37' |
            translate">
                </h3>


                <div>
                    <p class="font-weight-style" [innerHtml]="'help.download2' |
            translate"></p>
                    <p class="font-weight-style" [innerHtml]="'help.download3' |
            translate"></p>
                    <ol>
                        <li [innerHtml]="'help.download4' | translate"></li>
                        <li [innerHtml]="'help.download5' | translate"></li>
                        <img src="assets/images/melco/download_1.png" alt="Image Not Available">
                        <li [innerHtml]="'help.download6' | translate"></li>
                        <img src="assets/images/melco/download_2.png" alt="Image Not Available">
                        <li [innerHtml]="'help.download7' | translate"></li>
                        <img src="assets/images/melco/download_3.png" alt="Image Not Available">
                        <li [innerHtml]="'help.download8' | translate"></li>
                    </ol>
                    <p class="font-weight-style" [innerHtml]="'help.download9' | translate"></p>
                    <ol>
                        <li [innerHtml]="'help.download10' | translate"></li>
                        <li [innerHtml]="'help.download11' | translate"></li>
                        <li [innerHtml]="'help.download12' | translate"></li>
                        <li [innerHtml]="'help.download13' | translate"></li>
                        <li [innerHtml]="'help.download14' | translate"></li>
                        <li [innerHtml]="'help.download15' | translate"></li>
                    </ol>
                </div>
            </div>

        </div>
        <div id="transferMoreInfo" class="transferMoreInfo" style="display: block;" *ngIf="subscribedData1=='transfer'">
            <div class="helpSpanClass">
                <h3 id="helpDownload1" style="color: #428bca !important;" [innerHtml]="'help.transfer1' | translate">
                </h3>

                <p class="font-weight-style" [innerHtml]="'help.transfer2' | translate"></p>
                <p><i [innerHtml]="'help.transfer3' | translate"></i></p>
                <p class="font-weight-style" [innerHtml]="'help.transfer4' | translate"></p>

                <ol>
                    <li [innerHtml]="'help.transfer5' | translate"></li>
                    <li [innerHtml]="'help.transfer6' | translate"></li>
                </ol>

                <p class="font-weight-style" [innerHtml]="'help.transfer7' | translate"></p>
                <p class="font-weight-style" [innerHtml]="'help.transfer8' | translate"></p>
                <p class="font-weight-style" [innerHtml]="'help.transfer9' | translate"></p>

                <ol>
                    <li [innerHtml]="'help.transfer10' | translate"></li>
                    <li [innerHtml]="'help.transfer11' | translate"></li>
                    <li>
                        <div [innerHtml]="'help.transfer12' | translate"></div>
                        <div [innerHtml]="'help.transfer13' | translate"></div>
                        <div [innerHtml]="'help.transfer14' | translate"></div>
                        <div [innerHtml]="'help.transfer15' | translate"></div>
                        <div [innerHtml]="'help.transfer16' | translate"></div>
                        <div [innerHtml]="'help.transfer17' | translate"></div>
                        <div [innerHtml]="'help.transfer18' | translate"></div>
                        <div [innerHtml]="'help.transfer19' | translate"></div>
                        <img src="assets/images/melco/transfer_1.png" alt="Image Not Available"><br>
                        <p style="color: red;" [innerHtml]="'help.transfer20' | translate"></p><br>
                    </li>
                    <li>
                        <div [innerHtml]="'help.transfer21' | translate"></div>
                        <div [innerHtml]="'help.transfer22' | translate"></div>
                        <div [innerHtml]="'help.transfer23' | translate"></div>
                    </li>
                </ol>


            </div>
        </div>
        <div id="installMoreInfo" class="installMoreInfo" style="display: block;" *ngIf="subscribedData1=='install'">
            <div class="helpSpanClass">
                <h3 id="helpInstall5" style="color: #428bca !important;" [innerHtml]="'help.install1' | translate"></h3>
                <div class="helpcaution" [innerHtml]="'help.caution' | translate"></div>
                <ol>
                    <li [innerHtml]="'help.install2' | translate"></li>
                    <li [innerHtml]="'help.install3' | translate"></li>
                    <li [innerHtml]="'help.install4' | translate"> </li>
                    <li [innerHtml]="'help.install5' | translate"></li>
                    <li [innerHtml]="'help.install6' | translate"></li>
                    <li [innerHtml]="'help.install7' | translate"></li>
                    <li [innerHtml]="'help.install8' | translate"></li>
                </ol>
                <div style="height: 600px; margin-top: 30px; direction: ltr;font-weight: 500;">
                    <div style="margin-left: 110px;">
                        <span [innerHtml]="'help.install9' | translate"></span>
                        <br> <img src="assets/images/melco/down_arrow.png" alt="Image Not Available"
                            style="margin-left: 30px;"><br>
                    </div>
                    <div style="float: left; margin-top: 20px;">
                        <img src="assets/images/melco/install_1.png" alt="Image Not Available"
                            style="width: 300px;"><br>
                        <span style="margin-left: 110px;"></span>
                        <span [innerHtml]="'help.install10' | translate"></span>
                    </div>
                    <div class="installfinish">
                        <span [innerHtml]="'help.install13' | translate"></span>
                    </div>
                    <div style="margin-left: 40%; margin-right: 40%; margin-top: 50px;">
                        <span [innerHtml]="'help.install12' | translate"></span>
                        <br> <span style="margin-left: 60px;"></span><img
                            src="assets/images/melco/circle_right_bottom_arrows.png" alt="Image Not Available">
                    </div>
                    <table style="float: right; margin-right: 10%;">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <img src="assets/images/melco/install_3.png" alt="Image Not Available"><br>
                                        <div style="width: 250px;">
                                            <span [innerHtml]="'help.install11' | translate"></span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div style="margin-bottom: 60%; margin-left: 30%;">
                                        <span style="margin-left: 80px;"></span><img
                                            src="assets/images/melco/down_arrow.png" alt="Image Not Available"><br>
                                        <div style="width: 250px;">
                                            <span [innerHtml]="'help.install14' | translate"></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>