import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-opel-help-section',
  templateUrl: './opel-help-section.component.html',
  styleUrls: ['./opel-help-section.component.less']
})
export class OpelHelpSectionComponent implements OnInit, OnDestroy {

  @Input() subscribedData1: any;
  locale!: string;
  unsubscriptionLocale = new Subscription();

  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.unsubscriptionLocale = this.dataService.localeCodeCookie.subscribe((res: string) => {
      this.locale = res;
    })
  }

  ngOnDestroy(): void {
    this.unsubscriptionLocale.unsubscribe();
  }

}
