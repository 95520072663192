import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-app-pages',
  templateUrl: './app-pages.component.html',
  styleUrls: ['./app-pages.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class AppPagesComponent implements OnInit {
  favIcon = <HTMLLinkElement>document.querySelector('#appIcon');
  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.favIcon.setAttribute("href", `assets/images/melco/favicon.png`);
    }, 1000);
  }

}
