import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-melco-my-downloads',
  templateUrl: './melco-my-downloads.component.html',
  styleUrls: ['./melco-my-downloads.component.less']
})
export class MelcoMyDownloadsComponent implements OnInit, OnChanges {

  downloadUrlData: {
    ID: number,
    PACKAGE_ID: number,
    NAME: string,
    URL_DESC: string
  }[] = [];
  oemName!: string;
  @Input() package: any;
  downloadPopupToggle = false;
  popupDisplayData = {};
  rtlLocale = '';
  selectedLocale = false;
  selectedRegionDK = false;
  selectedLocaleDateHRline = false;
  selectedLocalePLMXCZDK = false;
  selectedLocaleNO = false;
  extendMarginToggle = false;
  selectedRegionTR = false;
  constructor(
    private dataService: DataService,
    private translate: TranslateService
  ) {
    if (this.translate.currentLang === 'melco/ar') {
      this.rtlLocale = 'rtl';
    } else {
      this.rtlLocale = 'non-rtl';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.package = [];
      if (this.translate.currentLang === 'melco/ar') {
        this.rtlLocale = 'rtl';
      } else {
        this.rtlLocale = 'non-rtl';
      }
    });
  }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
    this.checkForLocale(this.translate.currentLang);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.package = [];
      console.log(this.translate.currentLang);

      this.checkForLocale(this.translate.currentLang);

    });
  }

  checkForLocale(localeName: string) {
    switch (localeName) {
      case 'melco/tr_TR':
        this.selectedLocale = true;
        this.extendMarginToggle = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.selectedLocaleDateHRline = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/sv_SE':
        this.selectedLocaleDateHRline = true;
        this.selectedLocale = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.extendMarginToggle = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/pt_PT':
        this.selectedLocaleDateHRline = true;
        this.selectedLocale = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.extendMarginToggle = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/it_IT':
        this.selectedLocaleDateHRline = true;
        this.selectedLocale = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.extendMarginToggle = true;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/in':
        this.selectedLocaleDateHRline = true;
        this.selectedLocale = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/fr_CA':
        this.selectedLocaleDateHRline = true;
        this.selectedLocale = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.extendMarginToggle = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/fr_FR':
        this.selectedLocaleDateHRline = true;
        this.selectedLocale = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.extendMarginToggle = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/fi_FI':
        this.selectedLocaleDateHRline = true;
        this.selectedLocale = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.extendMarginToggle = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/es_ES':
        this.selectedLocaleDateHRline = true;
        this.selectedLocale = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.extendMarginToggle = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/pl_PL':
        this.selectedLocalePLMXCZDK = true;
        this.selectedLocaleDateHRline = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.selectedLocale = false;
        this.extendMarginToggle = false;
        break;
      case 'melco/es_MX':
        this.selectedLocalePLMXCZDK = true;
        this.selectedLocaleDateHRline = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.selectedLocale = false;
        this.extendMarginToggle = false;
        break;
      case 'melco/cs_CZ':
        this.selectedLocalePLMXCZDK = true;
        this.selectedLocaleDateHRline = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.selectedLocale = false;
        break;
      case 'melco/da_DK':
        this.selectedLocalePLMXCZDK = true;
        this.selectedRegionDK = true;
        this.selectedLocaleDateHRline = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.selectedLocale = false;
        this.extendMarginToggle = false;
        break;
      case 'melco/nb_NO':
        this.selectedLocaleNO = true;
        this.selectedLocaleDateHRline = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocale = false;
        this.extendMarginToggle = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/de_DE':
        this.extendMarginToggle = true;
        this.selectedLocaleDateHRline = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.selectedLocale = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/pt_BR':
        this.extendMarginToggle = true;
        this.selectedLocaleDateHRline = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.selectedLocale = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      case 'melco/ru_RU':
        this.extendMarginToggle = true;
        this.selectedLocaleDateHRline = false;
        this.selectedRegionDK = false;
        this.selectedRegionTR = false;
        this.selectedLocaleNO = false;
        this.selectedLocale = false;
        this.selectedLocalePLMXCZDK = false;
        break;
      default:
        this.selectedLocale = false;
        this.selectedLocaleDateHRline = false;
        this.selectedLocalePLMXCZDK = false;
        this.selectedLocaleNO = false;
        this.extendMarginToggle = false;
        this.selectedRegionTR = false;
        break;
    }
  }

  ngOnChanges(): void {
    if (this.selectedLocale) {
      for (const val of this.package) {
        this.translate.get(val[0].NAME).subscribe((result: any) => {
          if (result === 'Afrika'
            || result === 'Rusya'
            || result === 'Orta Doğu (İsrail hariç)'
            || result === 'İsrail'
          ) {
            this.selectedRegionTR = false;
          } else {
            this.selectedRegionTR = true;
          }
        });
      }
    }
  }

  getUrlData(id: number, name: string, version: string) {
    this.downloadUrlData = [];
    this.downloadUrlData = this.dataService.getUrlMapData(id);
    this.popupDisplayData = {
      mapName: name,
      mapVersion: version
    }
    this.downloadPopupToggle = true;
  }

  closePopup(e: any) {
    this.downloadPopupToggle = e;
  }
}