import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CDP';
  oemName = environment.oem;
  event$: any;
  isAppExist = false;
  constructor(
    private router: Router
  ) {
    this.router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        this.isAppExist = window.location.href.includes('downloads/apps');
      }
    });
  }
}
