<div class="tt-body">
    <div class="container">
        <div class="tt-wrapper">
            <div class="tt-overlay"></div>
            <div class=" tt-container tt-banner-module">
                <div id="bannerCarousel" class="carousel slide" data-ride="carousel">
                    <!-- Indicators -->
                    <ol class="carousel-indicators hidden-md hidden-lg">
                        <li data-target="#bannerCarousel" data-slide-to="0" class="active"></li>
                    </ol>
                    <div class="item active">
                        <div class="tt-banner-container">
                            <div class="tt-fixed" align="center">
                                <nav role="navigation" id="top" class="tt-main-navigation-module"
                                    [ngClass]="{'tabcontainer':oemName!=='opel','tabcontainer-opel':oemName==='opel','tabcontainer-ssangyong':oemName==='ssangyong'}">
                                    <div class="tt-navigation-containerHelp">
                                        <div class="tt-gradient-border">
                                            <ul [hidden]="oemName == 'melco'" class="tt-nav tt-nav-left helpNav" type="none"
                                                [ngClass]="{'headingStyle-opel':oemName==='opel','headingStyle-ssangyong':oemName==='ssangyong'}">
                                                <li class="moreInfoIdHelp">
                                                    <a id="downloadMoreInfoIdHelp" [ngClass]="{'downloadMoreInfoIdHelp':oemName!=='opel','downloadMoreInfoIdHelp-opel':oemName==='opel',
                                                        'downloadMoreInfoIdHelp-ssangyong':oemName==='ssangyong'}"
                                                        routerLink="/downloads/help" [queryParams]="{'id':'download'}"
                                                        routerLinkActive="active-link" [innerHtml]="'help.download' |
                                                        translate">
                                                    </a>
                                                </li>
                                                <li class="moreInfoIdHelp">
                                                    <a id="transferMoreInfoIdHelp" [ngClass]="{'transferMoreInfoIdHelp':oemName!=='opel','transferMoreInfoIdHelp-opel':oemName==='opel',
                                                        'transferMoreInfoIdHelp-ssangyong':oemName==='ssangyong'}"
                                                        routerLink="/downloads/help" [queryParams]="{'id':'transfer'}"
                                                        routerLinkActive="active-link" [innerHtml]="'help.transfer' |
                                                        translate">
                                                    </a>
                                                </li>
                                                <li class="moreInfoIdHelp">
                                                    <a id="installMoreInfoIdHelp" [ngClass]="{'installMoreInfoIdHelp':oemName!=='opel','installMoreInfoIdHelp-opel':oemName==='opel',
                                                        'installMoreInfoIdHelp-ssangyong':oemName==='ssangyong'}"
                                                        routerLink="/downloads/help" [queryParams]="{'id':'install'}"
                                                        routerLinkActive="active-link" [innerHtml]="'help.install' |
                                                        translate">
                                                    </a>
                                                </li>
                                            </ul>
                                            <ul class="tt-nav tt-nav-left helpNav" type="none"
                                                [hidden]="oemName !== 'melco'"
                                                [ngClass]="{'headingStyle-opel':oemName==='opel'}">
                                                <li class="moreInfoIdHelp">
                                                    <a id="downloadMoreInfoIdHelp"
                                                        [ngClass]="{'downloadMoreInfoIdHelp':oemName!=='opel','downloadMoreInfoIdHelp-opel':oemName==='opel'}"
                                                        routerLink="/downloads/help" [queryParams]="{'id':'download'}"
                                                        routerLinkActive="active-link" [innerHtml]="'index.download1' |
                                                        translate">
                                                    </a>
                                                </li>
                                                <li class="moreInfoIdHelp">
                                                    <a id="transferMoreInfoIdHelp"
                                                        [ngClass]="{'transferMoreInfoIdHelp':oemName!=='opel','transferMoreInfoIdHelp-opel':oemName==='opel'}"
                                                        routerLink="/downloads/help" [queryParams]="{'id':'transfer'}"
                                                        routerLinkActive="active-link" [innerHtml]="'index.transfer' |
                                                        translate">
                                                    </a>
                                                </li>
                                                <li class="moreInfoIdHelp">
                                                    <a id="installMoreInfoIdHelp"
                                                        [ngClass]="{'installMoreInfoIdHelp':oemName!=='opel','installMoreInfoIdHelp-opel':oemName==='opel'}"
                                                        routerLink="/downloads/help" [queryParams]="{'id':'install'}"
                                                        routerLinkActive="active-link" [innerHtml]="'index.install' |
                                                        translate">
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class=" tt-container tt-dashboard-module">
            <app-fca-help-section *ngIf="oemName==='stellantis'"
                [subscribedData1]="subscribedData"></app-fca-help-section>
            <app-iveco-help-section *ngIf="oemName==='iveco'"
                [subscribedData1]="subscribedData"></app-iveco-help-section>
            <app-ssangyong-help-section *ngIf="oemName==='ssangyong'"
                [subscribedData1]="subscribedData"></app-ssangyong-help-section>
            <app-opel-help-section *ngIf="oemName==='opel'" [subscribedData1]="subscribedData"></app-opel-help-section>
            <app-melco-help-section *ngIf="oemName==='melco'"
                [subscribedData1]="subscribedData"></app-melco-help-section>
            <app-mgn-help-section *ngIf="oemName==='mitsubishi'"
                [subscribedData1]="subscribedData"></app-mgn-help-section>
        </div>
    </div>
</div>