<div *ngIf="downloadUrlData.length>0" id="downloadPopup1" class="modal" [ngClass]="{'modal-display':modalToggle}">
    <div class="container downloadPopup">
        <div class="innerTitleDiv dialogDownloadTitle"><span
                class="innerTitleLeftSpan">{{popupDisplayData.mapName}}</span><span
                class="innerTitleRightSpan">{{popupDisplayData.mapVersion}}</span></div>
        <div class="mainPopdiv" id="downloadApplet">
            <!-- <div id="altDiv" [ngClass]="{'addScroll': downloadMapUrls.length>1}">
                <p class="download-below-files-style">{{'index.download_below_files' | translate}}</p>

                <div *ngFor="let value of downloadMapUrls;let i=index">
                    <a class="link-style" href="{{value}}" target="_blank">{{popupDisplayData.mapName}}</a>
                </div>
                <br>
            </div> -->

            <div id="altDiv">
                <p class="download-below-files-style" [innerHtml]="'index.download_below_files' | translate"></p>
                <div *ngFor="let value of downloadMapUrls;let i=index">
                    <a class="link-style" href="{{value.URL_DESC}}" target="_blank">{{value.NAME}}</a>
                </div>
            </div>
            <div class="popuptitle bld" [innerHtml]="'index.popup_title' | translate">

            </div>
            <div class="para1 bld" *ngIf="!checkMatchValue(selectedSeriesName) && !checkMatchValueForOtherSeries(selectedSeriesName)" [innerHtml]="'index.popup_para1' | translate"></div>
            <div class="para1 bld" *ngIf="checkMatchValue(selectedSeriesName)" [innerHtml]="'index.popup_para_storage' | translate"></div>
            <div class="para1 bld" *ngIf="checkMatchValueForOtherSeries(selectedSeriesName)" [innerHtml]="'index.popup_no_storage' | translate"></div>
            <div class="notice-tbl">
                <table>
                    <tr>
                        <th rowspan="5" [innerHtml]="'index.popup_table_column1_row1' | translate">

                        </th>
                        <th [innerHtml]="'index.popup_table_column2_row1' | translate">

                        </th>
                        <th [innerHtml]="'index.popup_table_column3_row1' | translate">

                        </th>
                    </tr>
                    <tr>
                        <td [innerHtml]="'index.popup_table_column2_row2' | translate">

                        </td>
                        <td [innerHtml]="'index.popup_table_column3_row2' | translate">

                        </td>
                    </tr>
                    <tr>
                        <td [innerHtml]="'index.popup_table_column2_row3' | translate">

                        </td>
                        <td>10</td>
                    </tr>
                    <tr>
                        <td [innerHtml]="'index.popup_table_column2_row4' | translate">

                        </td>
                        <td>1</td>
                    </tr>
                    <tr>
                        <td [innerHtml]="'index.popup_table_column2_row5' | translate">

                        </td>
                        <td>FAT32</td>
                    </tr>
                    <tr>
                        <td class="fontBold" [innerHtml]="'index.popup_table_column1_row6' | translate">

                        </td>
                        <td colspan="2" class="img-width"><img src="/assets/images/ssangyong/sandisk.jpg" /></td>
                    </tr>
                </table>
            </div>

        </div>
        <div class="innerDownloadButtonDiv">
            <input (click)="cancel()" type="button" class="innerDownloadButton" id="cancelButton"
                value="{{'index.cancel' | translate}}">
        </div>
    </div>
</div>