import { Component, OnInit, Input } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-iveco-my-downloads',
  templateUrl: './iveco-my-downloads.component.html',
  styleUrls: ['./iveco-my-downloads.component.less']
})
export class IvecoMyDownloadsComponent implements OnInit {

  downloadUrlData: {
    ID: number,
    PACKAGE_ID: number,
    NAME: string,
    URL_DESC: string
  }[] = [];
  oemName!: string;
  @Input() package: any;
  downloadPopupToggle = false;
  popupDisplayData = {};
  rtlLocale = '';

  constructor(
    private dataService: DataService,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.package = [];
    });
    if (this.translate.currentLang === 'iveco/ar' || this.translate.currentLang === 'iveco/he_IL') {
      this.rtlLocale = 'rtl';
    } else {
      this.rtlLocale = 'non-rtl';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.package = [];
      if (this.translate.currentLang === 'iveco/ar' || this.translate.currentLang === 'iveco/he_IL') {
        this.rtlLocale = 'rtl';
      } else {
        this.rtlLocale = 'non-rtl';
      }
    });
  }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
  }

  getUrlData(id: number, name: string, version: string) {
    this.downloadUrlData = [];
    this.downloadUrlData = this.dataService.getUrlMapData(id);
    this.popupDisplayData = {
      mapName: name,
      mapVersion: version
    }
    this.downloadPopupToggle = true;
  }

  closePopup(e: any) {
    this.downloadPopupToggle = e;
  }
}
