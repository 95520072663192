import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.less']
})
export class HelpComponent implements OnInit {

  oemName!: string;
  subscribedData = 'download';
  rtlLocale = '';

  constructor(
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
    this.activatedRoute.queryParams.subscribe(data => {
      this.subscribedData = data.id;
    });

    if (this.translate.currentLang === 'melco/ar') {
      this.rtlLocale = 'rtl';
    } else {
      this.rtlLocale = 'non-rtl';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.translate.currentLang === 'melco/ar') {
        this.rtlLocale = 'rtl';
      } else {
        this.rtlLocale = 'non-rtl';
      }
    });
  }

  setTab(tabName: string) {
    this.route.navigate([`/help`], { queryParams: { id: tabName } });
  }
}
