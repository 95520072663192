<div class="moreInfoDiv">
    <div *ngIf="subscribedData1=='download'" id="downloadMoreInfo" class="downloadMoreInfo">
        <div class="helpSpanClass">
            <h3 id="helpDownload1">
                {{'help.download1' | translate}}
            </h3>
            <p>
                {{'help.download2' | translate}}
            </p>
            <img src="/assets/images/opel/en_GB/1.png" alt="{{'index.alternate_text' | translate}}">
            <p>
                {{'help.download3' | translate}}
            </p>
            <h3 id="helpDownload1">
                {{'help.download4' | translate}}
            </h3>
            <ol>
                <li>{{'help.download5' | translate}}
                </li>
                <li>{{'help.download6' | translate}}
                </li>
                <img src="/assets/images/opel/{{locale}}/2.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.download7' | translate}}
                </li>
                <img src="/assets/images/opel/{{locale}}/3.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.download8' | translate}}
                </li>
                <img src="/assets/images/opel/{{locale}}/4.png" alt="{{'index.alternate_text' | translate}}">
                <img src="/assets/images/opel/{{locale}}/5.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.download9' | translate}}
                </li>
                <img src="/assets/images/opel/{{locale}}/6.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.download10' | translate}}
                </li>
                <img *ngIf="locale==='en_GB'" src="/assets/images/opel/{{locale}}/7.png"
                    alt="{{'index.alternate_text' | translate}}">
                <img *ngIf="locale!=='en_GB'" src="/assets/images/opel/{{locale}}/6.png"
                    alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.download11' | translate}}
                </li>
                <li>{{'help.download12' | translate}}
                </li>
            </ol>

            <h3 id="helpDownload35">
                {{'help.download13' | translate}}
            </h3>

            <div class="downloadMoreInfo-olstyle">
                <ol value="1">
                    <li [innerHtml]="'help.download14' | translate">
                    </li>
                    <li>{{'help.download15' | translate}}
                    </li>
                    <li>{{'help.download16' | translate}}
                    </li>
                    <li>{{'help.download17' | translate}}
                    </li>
                    <li>{{'help.download18' | translate}}
                    </li>

                </ol>
            </div>

        </div>

    </div>
    <div *ngIf="subscribedData1=='transfer'" id="transferMoreInfo" class="transferMoreInfo">
        <div class="helpSpanClass">
            <h3 id="helpDownload1">
                {{'help.transfer1' | translate}}
            </h3>
            <p>
                {{'help.transfer2' | translate}}
            </p>
            <ol>
                <li>{{'help.transfer3' | translate}}
                </li>
                <li>{{'help.transfer4' | translate}}
                </li>
                <li>{{'help.transfer5' | translate}}
                </li>
                <img src="/assets/images/opel/en_GB/14.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.transfer6' | translate}}
                </li>
                <img src="/assets/images/opel/en_GB/15.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.transfer7' | translate}}
                </li>
                <li>{{'help.transfer8' | translate}}
                </li>
                <li>{{'help.transfer9' | translate}}
                </li>
                <li>{{'help.transfer10' | translate}}
                </li>
                <li>{{'help.transfer11' | translate}}
                </li>
                <li>{{'help.transfer12' | translate}}
                </li>
            </ol>
        </div>
    </div>
    <div *ngIf="subscribedData1=='install'" id="installMoreInfo" class="installMoreInfo">
        <div class="helpSpanClass">
            <h3 id="helpDownload1">
                {{'help.install1' | translate}}
            </h3>
            <ol>
                <li>{{'help.install2' | translate}}
                </li>
                <li>{{'help.install3' | translate}}
                </li>
                <li>{{'help.install4' | translate}}
                </li>
                <img src="/assets/images/opel/{{locale}}/8.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.install5' | translate}}
                </li>
                <li>{{'help.install6' | translate}}
                </li>
                <img src="/assets/images/opel/{{locale}}/9.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.install7' | translate}}
                </li>
                <li>{{'help.install8' | translate}}
                </li>
                <img src="/assets/images/opel/{{locale}}/10.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.install9' | translate}}
                </li>
                <img src="/assets/images/opel/{{locale}}/11.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.install10' | translate}}
                </li>
                <img src="/assets/images/opel/{{locale}}/12.png" alt="{{'index.alternate_text' | translate}}">
                <img src="/assets/images/opel/{{locale}}/13.png" alt="{{'index.alternate_text' | translate}}">
                <li>{{'help.install11' | translate}}
                </li>
                <li>{{'help.install12' | translate}}
                </li>
            </ol>
        </div>
    </div>
</div>