import { Component, Input, ViewEncapsulation  } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mgn-help-section',
  templateUrl: './mgn-help-section.component.html',
  styleUrls: ['./mgn-help-section.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class MgnHelpSectionComponent {

  @Input() subscribedData1: any;
  rtlLocale = '';
  constructor(
    private translate: TranslateService
  ) {
    if (this.translate.currentLang === 'mitsubishi/ar') {
      this.rtlLocale = 'rtl';
    } else {
      this.rtlLocale = 'non-rtl';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.translate.currentLang === 'mitsubishi/ar') {
        this.rtlLocale = 'rtl';
      } else {
        this.rtlLocale = 'non-rtl';
      }
    });
  }

}
