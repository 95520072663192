<div class="tt-content-container">
  <div class="container tt-banner-copy-container">
    <form class="dropdown-form" [formGroup]="dropDownForm">
      <div>
        <div class="selectModelDiv">
          <span class="col-sm-12 col-md-12 col-lg-12 selectModel pl-0">
            {{'index.get_latest_software' | translate}}
          </span>
          <div class="dropdown">
            <div class="selectionDiv">
              <div class="" *ngFor="let vehicleModel of vehicleInfo; let i = index">
                <div *ngIf="i==0">
                  <div class="btn-group bootstrap-select seriesNbr open" [ngClass]="{'dropup':scrollDown<100}">
                    <input type="button" id="firstId" [class.error]="submitted && f['firstDropDown'].errors"
                      value="{{(dropDownForm.value.firstDropDown ? dropDownForm.value.firstDropDownVal : 'index.firstDropDown' | translate)}}"
                      (click)="openBrandDropDown()" (blur)="closeDropDown()"
                      class="btn dropdown-toggle btn-default drop-down-design"
                      [ngClass]="{'invalid': submitted && f['firstDropDown'].invalid}">
                    <span class="caret caret-design"
                      [ngClass]="{'invalid': submitted && f['firstDropDown'].invalid}"></span>
                    <div *ngIf="brandDropDownToggle" class="dropdown-menu open drop-down-height">
                      <ul class="dropdown-menu inner ">
                        <li [ngClass]="{'selected': dropDownForm.value.firstDropDown === null}">
                          <a (mousedown)="fetchCarModelData(null, null)" [innerHtml]="'index.firstDropDown'
                          | translate"></a>
                        </li>
                        <li *ngFor="let brand of firstDorpDownValue | order_by: 'NAME': oemCode.STELLANTIS"
                          [ngClass]="{'selected': dropDownForm.value.firstDropDown === brand.ID}">
                          <a (mousedown)="fetchCarModelData(brand.ID, brand.NAME)">{{brand?.NAME}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div *ngIf="i==1">
                  <div class="btn-group bootstrap-select seriesNbr open" [ngClass]="{'dropup':scrollDown<100}">
                    <input type="button" [class.error]="submitted && f['secondDropDown'].errors"
                      value="{{(dropDownForm.value.secondDropDown ? dropDownForm.value.secondDropDownVal : 'index.secondDropDown' | translate)}}"
                      (click)="openModelDropDown()" (blur)="closeDropDown()"
                      class="btn dropdown-toggle btn-default drop-down-design"
                      [ngClass]="{'invalid': submitted && f['secondDropDown'].invalid}">
                    <span class="caret caret-design"
                      [ngClass]="{'invalid': submitted && f['secondDropDown'].invalid}"></span>
                    <div *ngIf="modelDropDownToggle" class="dropdown-menu open second-drop-down-height">
                      <ul class="dropdown-menu inner ">
                        <li [ngClass]="{'selected': dropDownForm.value.secondDropDown === null}">
                          <a (mousedown)="fetchCarNaviData(null, null)" [innerHtml]="'index.secondDropDown'
                          | translate"></a>
                        </li>
                        <li *ngFor="let model of secondDropDownValue  | order_by: 'NAME': oemCode.STELLANTIS"
                          [ngClass]="{'selected': dropDownForm.value.secondDropDown === model.ID}">
                          <a (mousedown)="fetchCarNaviData(model.ID, model.NAME)">{{model?.NAME}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div *ngIf="i==2">

                  <div class="btn-group bootstrap-select seriesNbr open" [ngClass]="{'dropup':scrollDown<100}">
                    <input type="button" [class.error]="submitted && f['thirdDropDown'].errors"
                      value="{{(dropDownForm.value.thirdDropDown ? dropDownForm.value.thirdDropDownVal : 'index.thirdDropDown' | translate)}}"
                      (click)="openNaviDropDown()" (blur)="closeDropDown()"
                      class="btn dropdown-toggle btn-default drop-down-design"
                      [ngClass]="{'invalid': submitted && f['thirdDropDown'].invalid}">
                    <span class="caret caret-design"
                      [ngClass]="{'invalid': submitted && f['thirdDropDown'].invalid}"></span>
                    <div *ngIf="naviDropDownToggle" class="dropdown-menu open third-drop-down">
                      <ul class="dropdown-menu inner ">
                        <li [ngClass]="{'selected': dropDownForm.value.thirdDropDown === null}">
                          <a (mousedown)="setNaviDropDownValue(null, null)" [innerHtml]="'index.thirdDropDown'
                          | translate"></a>
                        </li>
                        <li *ngFor="let navi of thirdDropDownValue | order_by: 'NAME': oemCode.STELLANTIS"
                          [ngClass]="{'selected': dropDownForm.value.thirdDropDown === navi.ID}" >
                          <a (mousedown)="setNaviDropDownValue(navi.ID, navi.NAME)" >{{navi?.NAME}}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="search-style">
                <button name="search" id="search" class="commomButtonPrimary" (click)="fetchPackageData()">
                  {{'index.search' | translate}}
                </button>
              </div>

              <div id="dialog" title=""></div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <app-fca-my-downloads [package]="package" [formDataDropDown]="formDataDropDown"></app-fca-my-downloads>
</div>