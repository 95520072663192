<footer>
    <div [ngClass]='rtlLocale'>
        <div class="container tt-footer" [ngClass]="{'tt-footer-opel':oemName==='opel'}">
            <div class="tt-copyright">
                <span class="copyRightInfo">
                    <a *ngIf="oemName!=='opel'" id="copyright-anchor" (click)="routeToCopyright()"
                        [innerHtml]="('index.copyright'| translate) + ' ' +showYear+ ' '">
                    </a>
                    <a *ngIf="oemName==='opel'" (click)="routeToCopyright()" class="removingDeco"
                        [innerHtml]="('index.copyright'| translate) + ' ' +showYear+ ' '">
                    </a>
                    <span [innerHtml]="'index.copyright1'| translate"></span>
                </span>
                <span class="inassocMsg">
                    {{'index.copyright_asso'| translate}}
                    <img src='assets/images/TomTom-logo.png' class="print-logo" />
                </span>
            </div>
        </div>
    </div>
</footer>