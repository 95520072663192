import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-mgn-download-popup',
  templateUrl: './mgn-download-popup.component.html',
  styleUrls: ['./mgn-download-popup.component.less']
})
export class MgnDownloadPopupComponent implements OnInit {

  @Input() downloadUrlData: any;
  @Input() popupDisplayData: any;
  modalToggle = false;
  downloadMapUrls = [];
  downloadLicenseUrls = [];
  @Output() closePopup = new EventEmitter<boolean>();
  oemName!: string;
  rtlLocale = '';

  constructor(
    private dataService: DataService,
    private translate: TranslateService
  ) {
    if (this.translate.currentLang === 'mitsubishi/ar') {
      this.rtlLocale = 'rtl';
    } else {
      this.rtlLocale = 'non-rtl';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.translate.currentLang === 'mitsubishi/ar') {
        this.rtlLocale = 'rtl';
      } else {
        this.rtlLocale = 'non-rtl';
      }
    });
  }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
    for (const x of this.downloadUrlData) {
      this.downloadMapUrls = x.URL_DESC.split(';');
    }
    this.openModal();
  }

  openModal() {
    this.modalToggle = true;
  }

  cancel() {
    this.modalToggle = false;
    this.closePopup.emit(this.modalToggle);
  }

  ngOnDestroy(): void {
    this.downloadMapUrls = [];
    this.downloadLicenseUrls = [];
  }

  addUndeScoreInsteadOfBlankSpace(regionName: string) {
    return regionName.split(" ").join("_");
  }

}