import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from '../shared/components/error/error.component';
import { CdpComponent } from './cdp.component';
import { AppPagesComponent } from './components/app-pages/app-pages.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { HelpComponent } from './components/help/help.component';
import { HomeComponent } from './components/home/home.component';


const routes: Routes = [
    {
        path: '',
        component: CdpComponent,
        children: [
            {   path: '',
                redirectTo: 'downloads',
                pathMatch: 'full',
            },
            {
                path: 'downloads',
                component: HomeComponent
            },
            {
                path: 'downloads/help',
                component: HelpComponent
            },
            {
                path: 'downloads/copyright',
                component: CopyrightComponent
            },
            {
                path: 'downloads/apps',
                component: AppPagesComponent
            },
            {
                path: '**',
                component: ErrorComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CdpRoutingModule { }
