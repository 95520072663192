<div class="tt-my-downloads">
    <div *ngIf="package.length>0" class="container myDownloads" id="myDownloads">
        <h1 class="container downloadTitle" [innerHtml]="'index.download' | translate">
        </h1>
        <hr class="container dividerColor {{oemName}}-dividecolor-hrline">
        <div class="innerDownloadDiv-style" id="myInnerDownloadDiv">
            <div class="createDownloads" *ngFor="let data of package">
                <div class="outerMainDiv" *ngFor="let val of data">
                    <div class="innerTitleDiv innercss">
                        <span class="innerTitleLeftSpan">
                            {{val.NAME}}
                        </span>
                        <span class="innerTitleRightSpan" [hidden]="(val.DESCRIPTION === 'ssangyong.index.SoftwarePkgMessage' && selectedSeriesName === 'Y461') || (val.DESCRIPTION === 'ssangyong.index.SoftwarePkgMessage' && selectedSeriesName === 'Q261')">{{val.VERSION}}
                        </span>
                    </div>
                    <div class="innerTitleDiv-version innercss-version non-visibility" [ngClass]="{'visibility': (val.DESCRIPTION === 'ssangyong.index.SoftwarePkgMessage' && selectedSeriesName === 'Y461') || (val.DESCRIPTION === 'ssangyong.index.SoftwarePkgMessage' && selectedSeriesName === 'Q261')}">
                        <span class="innerTitleLeftSpan">
                            SOC:
                        </span>
                        <span class="">{{val.VERSION}}
                        </span><br/>
                        <span class="innerTitleLeftSpan">
                            MCU:
                        </span>
                        <span class="">{{val?.MCU_VERSION}}
                        </span>
                    </div>
                    
                    <hr class="hrLine innerhrline">
                    <div class="innerSupportDiv">
                        <!-- <img class="innerMainDiv" src="assets/images/{{oemName}}/Europe.jpg" alt="Download img"> -->
                        <img class="innerMainDiv" src="assets/images/{{oemName}}/{{val.IMAGE_NAME}}" alt="Download img">
                        <div class="innerSpanDiv" innerHTML="{{'index.MapMessage' | translate}}">
                        </div>
                        <div class="innerButtonDiv"><b>{{val.SIZE}}</b>
                            <button class="innerDownloadButton commomButtonPrimary"
                                (click)="getUrlData(val.ID,val.NAME,val.VERSION)"
                                [innerHtml]="'index.download1' | translate |titlecase"
                                [ngClass]="{'buttonWidthDeutsch':selectedLocaleDeutsch,'buttonWidthFrancais':selectedLocaleFrancais}">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-ssangyong-download-popup *ngIf="downloadPopupToggle" (closePopup)="closePopup($event)"
    [downloadUrlData]="downloadUrlData" [popupDisplayData]="popupDisplayData" [selectedSeriesName]="selectedSeriesName"></app-ssangyong-download-popup>