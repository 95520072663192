import { Component, Input, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-melco-help-section',
  templateUrl: './melco-help-section.component.html',
  styleUrls: ['./melco-help-section.component.less']
})
export class MelcoHelpSectionComponent implements OnInit {

  @Input() subscribedData1: any;
  rtlLocale = '';
  constructor(
    private translate: TranslateService
  ) {
    if (this.translate.currentLang === 'melco/ar') {
      this.rtlLocale = 'rtl';
    } else {
      this.rtlLocale = 'non-rtl';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.translate.currentLang === 'melco/ar') {
        this.rtlLocale = 'rtl';
      } else {
        this.rtlLocale = 'non-rtl';
      }
    });
  }

  ngOnInit(): void {
  }

}
