import {Pipe, PipeTransform} from '@angular/core';
import { OEMCODE } from '../constants/allOemCode';

@Pipe({
  name:'order_by',
  pure: true
})
export class SortPipe implements PipeTransform {

  transform(array: any, field: string, oem?:string): any[] {
    if (!Array.isArray(array)) {
      return [];
    }
    if ((oem && oem === OEMCODE.IVECO) || (oem && oem === OEMCODE.STELLANTIS)) {
      array.sort((a: any, b: any) => {
        if (a[field] < b[field]) {
          return -1;
        } else if (a[field] > b[field]) {
          return 1;
        } else {
          return 0;
        }
      });
      return array;
    } else {
      return array;
    }
  }
}