import { Injectable } from '@angular/core';

declare var utag: any;

@Injectable({
  providedIn: 'root'
})
export class TealiumService {
  currentEnvironment = '';

  constructor() {
    setTimeout(() => {
      const hostName = window.location.hostname;
      if ((hostName.includes('dev')) || (hostName.includes('test'))) {
        this.currentEnvironment =  `qa`;
      } else if ((hostName.includes('localhost'))) {
        this.currentEnvironment =  `dev`;
      } else {
        this.currentEnvironment = `prod`;
      }
      (() => {
        let a: string, b: Document, c: string, d: HTMLScriptElement;
        a = `//tags.tiqcdn.com/utag/tomtom/navshop/${this.currentEnvironment}/utag.js`;
        b = document;
        c = 'script';
        d = b.createElement(c) as HTMLScriptElement;
        d.src = a;
        d.type = 'text/javascript';
        d.async = true;
        b.head.appendChild(d);
      })();
    }, 1000);
  }

  trackEvent(data: any) {
    utag.link(data);
  }
}
