import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {

  constructor(
    private translateService: TranslateService,
    private titleService: Title,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.translateService.get('error.title').subscribe((result: string) => {
      this.titleService.setTitle(result);
    });

    this.dataService.isErrorPageLoaded.next(false);
  }

}
