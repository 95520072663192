import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.less']
})
export class CopyrightComponent implements OnInit {

  oemName: string = "";
  constructor(
    private dataService: DataService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
    if (this.oemName === 'melco' || this.oemName === 'mitsubishi') {
      const oem = 'mitsubishi'
      this.titleService.setTitle(`${oem.replace(oem[0], oem[0].toUpperCase())} | Copyright`);
    } else if (this.oemName === 'ssangyong') {
      // need to right logic for ssangyong casing
      this.titleService.setTitle(`SsangYong | Copyright`)
    }
    else {
      this.titleService.setTitle(`${this.oemName.replace(this.oemName[0], this.oemName[0].toUpperCase())} | Copyright`)
    }
  }
}
