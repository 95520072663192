<div *ngIf="downloadUrlData.length>0" id="downloadPopup1" class="modal" [ngClass]="{'modal-display':modalToggle}">
    <div class="container downloadPopup">
        <div class="innerTitleDiv dialogDownloadTitle"><span
                class="innerTitleLeftSpan">{{popupDisplayData.mapName}}</span><span
                class="innerTitleRightSpan">{{popupDisplayData.mapVersion}}</span></div>
        <div class="mainPopdiv" id="downloadApplet">
            <div id="altDiv" [ngClass]="{'addScroll': downloadMapUrls.length>1}">
                <p class="download-below-files-style">{{'index.download_below_files' | translate}}</p>

                <div *ngFor="let value of downloadMapUrls;let i=index">
                    <a class="link-style" (click)="downloadAndTrackProgress(value, 'download')" href="{{value}}" target="_blank">{{'index.download_map' | translate}}
                        {{downloadMapUrls.length>1?
                        '-'+(i+1):''}}</a>
                </div>
                <div *ngFor="let value of downloadLicenseUrls;let i=index">
                    <a class="link-style" href="{{value}}" target="_blank">{{'index.download_license' | translate}}
                        {{downloadLicenseUrls.length>1?
                        '-'+(i+1):''}}</a><br>
                </div>
                <br>
            </div>
        </div>
        <div class="innerDownloadButtonDiv">
            <input (click)="cancel()" type="button" class="innerDownloadButton" id="cancelButton"
                value="{{'index.cancel' | translate}}">
        </div>
    </div>
</div>