import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';

export enum OEM_BASED_APPCODE {
    Stellantis = 'stellantis',
    OPEL = 'opel',
    IVECO = 'iveco',
    SSANGYONG = 'ssangyong',
    MITSUBISHI = 'mitsubishi',
    MELCO = 'melco'
}

@Component({
    selector: 'app-root',
    templateUrl: './cdp.component.html',
    styleUrls: ['./cdp.component.less']
})
export class CdpComponent implements OnInit {
    title = 'CDP';
    oemName = '';
    favIcon = <HTMLLinkElement>document.querySelector('#appIcon');
    rtlLocale = '';
    constructor(
        private translateService: TranslateService,
        private translationService: TranslationService,
        private dataService: DataService,
        private cookieService: CookieService
    ) {
        
    }

    ngOnInit() {
        this.oemName = (environment.oem);
        const locCode = this.cookieService.get(`${environment.cookieName}`);
        if (locCode !== '' && locCode !== undefined) {
            this.translateService.use(this.translationService.getTranslationUrl(this.oemName, locCode));
        } else {
            this.translateService.use(this.translationService.getTranslationUrl(this.oemName, environment.defaultLangCode));
        }
        this.dataService.setOemName(this.oemName);
        this.loadFaviconIconBasedOnAppCode(this.oemName)
    }

    changeIcon(imageName: string) {
        this.favIcon.setAttribute("href", `assets/images/${this.oemName}/${imageName}`);
    }

    loadFaviconIconBasedOnAppCode(appCode: string | null) {
        switch (this.oemName) {
            case OEM_BASED_APPCODE.Stellantis:
                this.changeIcon('favicon.jpg');
                document.documentElement.style.setProperty("--font-family-regular", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--font-family-bold", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--font-family-extra-bold", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--font-family-copyrightpage", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--color", "#003087");
                break;
            case OEM_BASED_APPCODE.IVECO:
                document.documentElement.style.setProperty("--font-family-regular", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--font-family-bold", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--font-family-extra-bold", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--font-family-copyrightpage", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--color", "#003087");
                this.changeIcon('favicon-iveco.ico');
                break;
            case OEM_BASED_APPCODE.OPEL:
                document.documentElement.style.setProperty("--font-family-regular", "opel-sans");
                document.documentElement.style.setProperty("--font-family-bold", "opel-bold");
                document.documentElement.style.setProperty("--font-family-extra-bold", "opel-extra-bold");
                document.documentElement.style.setProperty("--font-family-copyrightpage", "opel-sans");
                document.documentElement.style.setProperty("--color", "#F7D900");
                this.changeIcon('favicon.opel.ico');
                break;
            case OEM_BASED_APPCODE.SSANGYONG:
                document.documentElement.style.setProperty("--font-family-regular", "'Montserrat'");
                document.documentElement.style.setProperty("--font-family-bold", "'Montserrat-Bold'");
                document.documentElement.style.setProperty("--font-family-extra-bold", "'Montserrat-Bold'");
                document.documentElement.style.setProperty("--font-family-copyrightpage", "'Montserrat-Regular'");
                document.documentElement.style.setProperty("--color", "#428bca");
                this.changeIcon('favicon1.png');
                break;
            case OEM_BASED_APPCODE.MITSUBISHI:
                this.checkRTLLocale();
                if (this.rtlLocale === 'non-rtl') {
                    document.documentElement.style.setProperty("--font-family-regular", "'Montserrat', sans-serif");
                    document.documentElement.style.setProperty("--font-family-bold", "'Montserrat', sans-serif");
                    document.documentElement.style.setProperty("--font-family-extra-bold", "'Montserrat', sans-serif");
                } else {
                    document.documentElement.style.setProperty("--font-family-regular", "NotoSansArabic-Regular");
                    document.documentElement.style.setProperty("--font-family-bold", "NotoSansArabic-Bold");
                    document.documentElement.style.setProperty("--font-family-extra-bold", "NotoSansArabic-Bold");
                }
                document.documentElement.style.setProperty("--color", "rgb(66, 139, 202)");
                this.changeIcon('favicon.png');
                break;
            case OEM_BASED_APPCODE.MELCO:
                document.documentElement.style.setProperty("--font-family-regular", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--font-family-bold", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--font-family-extra-bold", "'Montserrat', sans-serif");
                document.documentElement.style.setProperty("--color", "rgb(66, 139, 202)");
                this.changeIcon('favicon.jpg');
                break;
        }
    }

    checkRTLLocale() {
        if (this.translateService.currentLang === 'mitsubishi/ar') {
            this.rtlLocale = 'rtl';
        } else {
            this.rtlLocale = 'non-rtl';
        }
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            if (this.translateService.currentLang === 'mitsubishi/ar') {
                this.rtlLocale = 'rtl';
            } else {
                this.rtlLocale = 'non-rtl';
            }
        });
    }
}
