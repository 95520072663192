<div [ngClass]='rtlLocale'>
    <div class="container tt-container tt-dashboard-module">
        <div class="howToSection">
            <div class="tt-banner-preview-card downloadMoreInfoBlock" align="center"
                [ngClass]="{'tt-banner-preview-card-opel': oemName==='opel'}">
                <div class="tt-banner-preview-copy">
                    <span data-src="">
                        <img src="/assets/images/Download-icon.png" align="middle" alt="Image Not Available">
                    </span>
                    <h5 id="downloadHeader" [ngClass]="{'h5opel': oemName==='opel'}">
                        <span class="span-style" [innerHtml]="'index.step1' | translate"></span>&nbsp;
                        <span [innerHtml]="'index.download1' | translate"></span>
                    </h5>

                    <p class="para-font" [innerHtml]="'index.step1_message' | translate">
                    </p>
                </div>
                <button (click)="routeToHelp('download')" id="downloadMoreInfoId" [class.iveco]="oemName === 'iveco'">
                    <img [hidden]="oemName === 'iveco' || oemName === 'melco' || oemName === 'mitsubishi'"
                        class="cta-arrow" src="/assets/images/CTA_arrow.svg">

                    <span class="more-info" [ngClass]="{'more-info-melco': oemName==='melco'}"
                        [innerHtml]="'index.more_info' | translate"></span>
                </button>
            </div>


            <div class="tt-banner-preview-card transferMoreInfoBlock" align="center"
                [ngClass]="{'tt-banner-preview-card-opel': oemName==='opel'}">
                <div class="tt-banner-preview-copy">
                    <span data-src="">
                        <img src="/assets/images/Transfer-icon.png" align="middle" alt="Image Not Available">
                    </span>
                    <h5 id="transferHeader" [ngClass]="{'h5opel': oemName==='opel'}">
                        <span class="span-style" [innerHtml]="'index.step2' | translate"></span>&nbsp;
                        <span [innerHtml]="'index.transfer' | translate"></span>
                    </h5>

                    <p class="para-font" [innerHtml]="'index.step2_message' | translate">
                    </p>
                </div>
                <button (click)="routeToHelp('transfer')" id="transferMoreInfoId" [class.iveco]="oemName === 'iveco'">
                    <img [hidden]="oemName === 'iveco' || oemName === 'melco' || oemName === 'mitsubishi'"
                        class="cta-arrow" src="/assets/images/CTA_arrow.svg">
                    <span class="more-info" [ngClass]="{'more-info-melco': oemName==='melco'}"
                        [innerHtml]="'index.more_info' | translate"></span>
                </button>
            </div>


            <div class="tt-banner-preview-card installMoreInfoBlock" align="center"
                [ngClass]="{'tt-banner-preview-card-opel': oemName==='opel'}">
                <div class="tt-banner-preview-copy">
                    <span data-src="">
                        <img src="/assets/images/Install-icon.png" alt="Image Not Available" align="middle">
                    </span>
                    <h5 id="installHeader" [ngClass]="{'h5opel': oemName==='opel'}">
                        <span class="span-style" [innerHtml]="'index.step3' | translate"></span>&nbsp;
                        <span [innerHtml]="'index.install' | translate"></span>
                    </h5>

                    <p class="para-font" [innerHtml]="'index.step3_message' | translate">
                    </p>
                </div>
                <button (click)="routeToHelp('install')" id="installMoreInfoId" [class.iveco]="oemName === 'iveco'">
                    <img [hidden]="oemName === 'iveco' || oemName === 'melco' || oemName === 'mitsubishi'"
                        class="cta-arrow" src="/assets/images/CTA_arrow.svg">
                    <span class="more-info" [ngClass]="{'more-info-melco': oemName==='melco'}"
                        [innerHtml]="'index.more_info' | translate"></span>
                </button>
            </div>
        </div>
        <div *ngIf="oemName==='melco'">
            <div class="myDownloadFooter">
                <span [innerHtml]="'footer.message1' | translate"></span><br>
                <span [innerHtml]="'footer.message2' | translate"></span><br>
                <span class="hyper" [innerHtml]="'footer.message_hyperlink' | translate"></span>
            </div>
        </div>
    </div>
</div>