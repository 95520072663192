<div class="moblile-screens">

    <div class="container" style="padding-left: 0px;padding-right: 0px;">
        <div class="mgn-logo">

            <span class="logo-span"> <img src="assets/images/melco/mitsubishi_motors_logo.svg" alt="Image Not Available" class="banner-img">
            </span>
        </div>

        <div class="text">
            <p>Smartphone-link Display Audio Real Time Traffic</p>
        </div>

        <div class="buttons-menu">
            <div class="logo play-store">
                <a href="https://play.google.com/store/apps/details?id=com.mitsubishi_motors.Smartphone_linkDisplayAudioRealTimeTraffic" target="_blank"><img src="assets/images/melco/google_play_badge.svg" alt="Image Not Available"></a>
            </div>
            <div class="logo app-store">
                <a href="https://apps.apple.com/au/app/smartphone-link-display-audio/id1458576031" target="_blank"><img src="assets/images/melco/app_store_badge.svg" alt="Image Not Available"></a>
            </div>

        </div>

    </div>

</div>