<div class="tt-content-container">
    <div class="container tt-banner-copy-container">
        <form class="dropdown-form" [formGroup]="dropDownForm">
            <div>
                <div class="selectModelDiv">
                    <span class="col-sm-12 col-md-12 col-lg-12 selectModel pl-0">
                        {{'index.get_latest_software' | translate}}
                    </span>
                    <div class="dropdown">
                        <div class="selectionDiv" [ngClass]="{'reduce-dropdown-padding':selectedLocale}">
                            <div class="" *ngFor="let vehicleModel of vehicleInfo; let i = index">
                                <div *ngIf="i==0">
                                    <div class="btn-group bootstrap-select seriesNbr open">
                                        <input type="button" [class.error]="submitted && f['firstDropDown'].errors"
                                            value="{{(dropDownForm.value.firstDropDown ? dropDownForm.value.firstDropDownVal : 'index.firstDropDown' | translate)}}"
                                            (click)="openBrandDropDown()" (blur)="closeDropDown()"
                                            class="btn dropdown-toggle btn-default drop-down-design"
                                            [ngClass]="{'invalid': submitted && f['firstDropDown'].invalid}">
                                        <span class="caret caret-design"
                                            [ngClass]="{'invalid': submitted && f['firstDropDown'].invalid}"></span>
                                        <div *ngIf="brandDropDownToggle" class="dropdown-menu open dropdown-style">
                                            <ul class="dropdown-menu inner ">
                                                <li>
                                                    <a (mousedown)="fetchCarModelData(null, null)">{{'index.firstDropDown'
                                                        | translate}}</a>
                                                </li>
                                                <li *ngFor="let brand of firstDorpDownValue">
                                                    <a
                                                        (mousedown)="fetchCarModelData(brand.ID, brand.NAME)">{{brand?.NAME}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="i==1">
                                    <div class="btn-group bootstrap-select seriesNbr open">
                                        <input type="button" [class.error]="submitted && f['secondDropDown'].errors"
                                            value="{{(dropDownForm.value.secondDropDown ? dropDownForm.value.secondDropDownVal : 'index.secondDropDown' | translate)}}"
                                            (click)="openModelDropDown()" (blur)="closeDropDown()"
                                            class="btn dropdown-toggle btn-default drop-down-design"
                                            [ngClass]="{'invalid': submitted && f['secondDropDown'].invalid}">
                                        <span class="caret caret-design"
                                            [ngClass]="{'invalid': submitted && f['secondDropDown'].invalid}"></span>
                                        <div *ngIf="modelDropDownToggle"
                                            class="dropdown-menu open second-dropdown-style">
                                            <ul class="dropdown-menu inner ">
                                                <li>
                                                    <a (mousedown)="fetchCarNaviData(null, null)">{{'index.secondDropDown'
                                                        | translate}}</a>
                                                </li>
                                                <li *ngFor="let model of secondDropDownValue">
                                                    <a
                                                        (mousedown)="fetchCarNaviData(model.ID, model.NAME)">{{model?.NAME}}</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="search-style">
                                <button name="search" id="search" class="commomButtonPrimary"
                                    (click)="fetchPackageData()" [ngClass]="{'increase-button-width':selectedLocale}">
                                    <img class="cta-arrow" src="/assets/images/CTA_arrow.svg" height="15px">
                                    {{'index.search' | translate}}
                                </button>
                            </div>

                            <div id="dialog" title=""></div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <app-opel-my-downloads [package]="package"></app-opel-my-downloads>
</div>