import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {
  locales: {
    name: string,
    localeCode: string
  }[] = [];

  oemName: string = '';
  oemInfo: { ID: number, NAME: string } = { ID: 0, NAME: '' };
  vehicleModelInfo: {
    ID: number,
    NAME: string,
    OEM_ID: number
  }[] = [];
  localeForm!: FormGroup;

  container!: any;
  constructor(
    private dataService: DataService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
    this.oemInfo = this.dataService.getOemId(this.oemName);
    this.vehicleModelInfo = this.dataService.getVehicleInfo(this.oemInfo.ID);
  }

  routeHelp() {
    const name = this.dataService.getOemName();
    this.route.navigate([`/${name}/help`]);
  }
}
