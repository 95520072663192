import { Component, Input, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-ssangyong-my-downloads',
  templateUrl: './ssangyong-my-downloads.component.html',
  styleUrls: ['./ssangyong-my-downloads.component.less']
})
export class SsangyongMyDownloadsComponent implements OnInit {

  downloadUrlData: {
    ID: number,
    PACKAGE_ID: number,
    NAME: string,
    URL_DESC: string
  }[] = [];
  oemName!: string;
  @Input() package: any;
  @Input() selectedSeriesName = "";
  downloadPopupToggle = false;
  popupDisplayData = {};
  selectedLocaleDeutsch!: boolean;
  selectedLocaleFrancais!: boolean;

  constructor(
    private dataService: DataService,
    private translate: TranslateService
  ) {
    this.checkLocaleForDownloadButtonStyle();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.package = [];
      this.checkLocaleForDownloadButtonStyle();
    });
  }

  ngOnInit(): void {
    this.oemName = this.dataService.getOemName();
  }

  getUrlData(id: number, name: string, version: string) {
    this.downloadUrlData = [];
    this.downloadUrlData = this.dataService.getUrlMapData(id);
    this.popupDisplayData = {
      mapName: name,
      mapVersion: version
    }
    this.downloadPopupToggle = true;
  }

  checkLocaleForDownloadButtonStyle() {
    if (this.translate.currentLang.includes('de_DE')) {
      this.selectedLocaleDeutsch = true;
    } else if (this.translate.currentLang.includes('fr_FR')) {
      this.selectedLocaleFrancais = true;
    } else {
      this.selectedLocaleDeutsch = false;
      this.selectedLocaleFrancais = false;
    }
  }

  closePopup(e: any) {
    this.downloadPopupToggle = e;
  }
}