<div [ngClass]='rtlLocale'>
    <div class="tt-my-downloads">
        <div *ngIf="package.length>0" class="container myDownloads" id="myDownloads">
            <h1 class="container downloadTitle">
                {{'index.download' | translate}}
            </h1>
            <hr class="container dividerColor {{oemName}}-dividecolor-hrline">
            <div class="check-map-msg" [innerHTML]="'index.check_map' | translate"></div>
            <div class="innerDownloadDiv-style" id="myInnerDownloadDiv">
                <div class="createDownloads" *ngFor="let data of package">
                    <div class="outerMainDiv" *ngFor="let val of data">
                        <div class="innerTitleDiv innercss">
                            <span class="innerTitleLeftSpan">
                                {{val.NAME}}</span><br><span class="innerTitleRightSpan">{{val.VERSION}}
                            </span>
                            <br/>
                            <span class="innerTitleLeftSpan">
                                </span>
                                <!-- <span class="innerTitleRightSpan date-field-color">Uploaded date: {{val.UPLOAD_DATE | date: 'MM-yyyy'}}</span> -->
                                <span class="innerTitleRightSpan date-field-color">{{'index.release_number' | translate}}: {{val.RELEASE_VERSION}}</span>
                        </div>
                        <hr class="hrLine innerhrline">
                        <div class="innerSupportDiv"><img class="innerMainDiv"
                                src="assets/images/{{oemName}}/{{val.IMAGE_NAME}}" alt="Download img">
                            <div class="innerSpanDiv innerSpanCss" innerHTML="{{'index.MapMessage' | translate}}">
                            </div>
                            <div class="innerButtonDiv"><b>{{val.SIZE}}</b><button
                                    class="innerDownloadButton commomButtonPrimary"
                                    (click)="getUrlData(val.ID,val.NAME,val.VERSION)">{{'index.download1' |
                                    translate}}</button></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-iveco-download-popup *ngIf="downloadPopupToggle" (closePopup)="closePopup($event)"
    [downloadUrlData]="downloadUrlData" [popupDisplayData]="popupDisplayData"></app-iveco-download-popup>