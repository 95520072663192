<div [ngClass]='rtlLocale'>
    <div *ngIf="downloadUrlData.length>0" id="downloadPopup1" class="modal" [ngClass]="{'modal-display':modalToggle}">
        <div class="container downloadPopup">
            <div class="innerTitleDiv dialogDownloadTitle">
                <span class="innerTitleLeftSpan" [innerHtml]="popupDisplayData.mapName | translate"></span>
                <span class="innerTitleRightSpan" [innerHtml]="popupDisplayData.mapVersion | translate"></span>
            </div>
            <div class="mainPopdiv" id="downloadApplet">
                <div id="altDiv" [ngClass]="{'addScroll': downloadMapUrls.length>1}">
                    <p class="download-below-files-style" *ngIf="popupDisplayData.mapName === 'South Korea'"
                        [innerHTML]="'index.korea_text' | translate"></p>
                    <p class="download-below-files-style">{{'index.download_below_files' | translate}}</p>

                    <div *ngFor="let value of downloadMapUrls;let i=index">
                        <a class="link-style" href="{{value}}" target="_blank"
                            [innerHtml]="popupDisplayData.mapName | translate"></a>
                    </div>
                    <br>
                </div>
                <div class="popuptitle bld" [innerHtml]="'index.popup_title' | translate">

                </div>
                <div class="para1 bld" [innerHtml]="'index.popup_para1' | translate">
                </div>
                <div class="notice-tbl">
                    <table>
                        <tr>
                            <th rowspan="5" [innerHtml]="'index.popup_table_column1_row1' | translate">
                            </th>
                            <th [innerHtml]="'index.popup_table_column2_row1' | translate">
                            </th>
                            <th [innerHtml]="'index.popup_table_column3_row1' | translate">
                            </th>
                        </tr>
                        <tr>
                            <td class="font-weight-style" [innerHtml]="'index.popup_table_column2_row2' | translate">
                            </td>
                            <td class="font-weight-style" [innerHtml]="'index.popup_table_column3_row2' | translate">
                            </td>
                        </tr>
                        <tr>
                            <td class="font-weight-style" [innerHtml]="'index.popup_table_column2_row3' | translate">
                            </td>
                            <td class="font-weight-style">10</td>
                        </tr>
                        <tr>
                            <td class="font-weight-style" [innerHtml]="'index.popup_table_column2_row4' | translate">
                            </td>
                            <td class="font-weight-style">1</td>
                        </tr>
                        <tr>
                            <td class="font-weight-style" [innerHtml]="'index.popup_table_column2_row5' | translate">
                            </td>
                            <td class="font-weight-style">FAT32</td>
                        </tr>
                        <tr>
                            <td class="fontBold" [innerHtml]="'index.popup_table_column1_row6' | translate">
                            </td>
                            <td colspan="2" class="img-width"><img src="/assets/images/mitsubishi/sandisk.jpg" /></td>
                        </tr>
                    </table>
                </div>
                <div class="innerDownloadButtonDiv">
                    <input (click)="cancel()" type="button" class="innerDownloadButton" id="cancelButton"
                        value="{{'index.cancel' | translate}}">
                </div>
            </div>
        </div>
    </div>