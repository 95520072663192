import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.less']
})
export class StepperComponent implements OnInit {

  oemName = "";
  steppersData: any[] = [];
  rtlLocale = '';

  constructor(
    private router: Router,
    private translate: TranslateService
  ) {
    this.oemName = (environment.oem);
    if (this.translate.currentLang === 'melco/ar'
      || this.translate.currentLang === 'mitsubishi/ar'
      || this.translate.currentLang === 'melco/ar'
      || this.translate.currentLang === 'iveco/ar' || this.translate.currentLang === 'iveco/he_IL') {
      this.rtlLocale = 'rtl';
    } else {
      this.rtlLocale = 'non-rtl';
    }
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      if (this.translate.currentLang === 'melco/ar'
        || this.translate.currentLang === 'mitsubishi/ar'
        || this.translate.currentLang === 'melco/ar'
        || this.translate.currentLang === 'iveco/ar' || this.translate.currentLang === 'iveco/he_IL') {
        this.rtlLocale = 'rtl';
      } else {
        this.rtlLocale = 'non-rtl';
      }
    });
  }

  ngOnInit(): void {
  }

  routeToHelp(type: string) {
    this.router.navigate([`/downloads/help`], { queryParams: { id: type } });
    window.scrollTo(0, 0);
  }

}
